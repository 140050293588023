import { io } from "socket.io-client";
import createNamedContext from "./createNamedContext";

const SOCKET_URL = "/";

const socket = io(SOCKET_URL, {
    autoConnect: false,
    transports: ["websocket", "polling"],
    path: `${process.env.REACT_APP_PUBLIC_URL}/socket`,
});
const SocketContext = createNamedContext("socket");

export const SocketProvider = ({ children }) => {
    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketContext;
