import { useState, useRef } from "react";
import createNamedContext from "./createNamedContext";
import { useToastAutoClose } from "../hooks/useToastAutoClose";
import { uuid } from "../utils/commonUtils";

const ToastContext = createNamedContext("toast");

export const ToastProvider = ({ children }) => {
	const [toasts, setToasts] = useState([]);

	const [autoCloseTime, setAutoCloseTime] = useState(10000);
	const [autoClose, setAutoClose] = useState(true);

	const addMessage = useRef(() => {});

	addMessage.current = ({ mode, message, sender, goToUrl }) => {
		const toast = { mode, message, sender, goToUrl };
		setToasts((prevToasts) => {
			return [...prevToasts, { ...toast, id: uuid() }];
		});
	};

	useToastAutoClose({
		toasts,
		setToasts,
		autoClose,
		autoCloseTime,
	});

	const removeToast = (id) => {
		setToasts(toasts.filter((t) => t.id !== id));
	};

	const contextValue = {
		setAutoClose,
		setAutoCloseTime,
		removeToast,
		addMessage: addMessage,
		toasts,
	};

	return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>;
};

export default ToastContext;
