import React from "react";
import { Route, Redirect } from "react-router";
import { ROUTE_PATH } from "../../constants/routePaths";
import useAuth from "../../hooks/useAuth";

const PublicRoute = ({ children, restricted, ...rest }) => {
    const auth = useAuth();
    
    if (auth.isLogin.current() && restricted) {
        return <Redirect to={`/${ROUTE_PATH.FISHING_APP_HOME}`} />
    }
    
    return (
        <Route {...rest}>
            {children}
        </Route>
    )
}

export default PublicRoute;