const componentLoader = (lazyComponent, attemptsLeft = 5) => {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                // retry after 1500 ms
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        window.location.reload();
                        reject(error);
                        return;
                    }
                    componentLoader(lazyComponent, attemptsLeft - 1).then(
                        resolve,
                        reject
                    );
                }, 1500);
            });
    });
};

export default componentLoader;
