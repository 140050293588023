import axios from "axios";
import store from "../../app/store";
import { USER_ACCOUNT_STATUS } from "../../constants/user";
// import history from "../../app/history";
import { setError } from "../../features/error/errorSlice";
import { getNewAccessToken } from "../../features/user/userSlice";

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_PUBLIC_URL}/api/v1/`,
});

// log send request
instance.interceptors.request.use((req) => {
    // console.log("Starting Request", JSON.stringify(req, null, 2));
    return req;
});

instance.interceptors.response.use(
    (res) => {
        // console.log("RECEIVING res:");
        // console.log(res);

        return res;
    },
    (error) => {
        // console.log("AXIOS ERROR: response error:");

        let errorObject = {
            message: "出現了未知的錯誤",
        };

        if (error.response) {
            // 5xx / 4xx error

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);

            const { status } = error.response;

            // custom error handling
            if (status === 500) {
                // error.response.message = error.response.data;

                store.dispatch(setError(errorObject));
                window.scrollTo(0, 0);
            }
            if (status === 404) {
                // store.dispatch(setError(error.response.data.error));
                store.dispatch(setError(errorObject));
                window.scrollTo(0, 0);
            }

            if (status === 403) {
                if (
                    store.getState().user?.user.status ===
                    USER_ACCOUNT_STATUS.PROVISIONED
                ) {
                    store.dispatch(
                        setError({ message: "請先激活你的帳戶以完成操作" })
                    );
                    window.scrollTo(0, 0);
                }
            }

            if (status === 401) {
                return store
                    .dispatch(getNewAccessToken())
                    .unwrap()
                    .then((result) => {
                        // console.log({ result });

                        instance.defaults.headers.common[
                            "Authorization"
                        ] = `Bearer ${result.accessToken}`;
                        error.config.headers[
                            "Authorization"
                        ] = `Bearer ${result.accessToken}`;

                        return instance.request(error.config);
                    });
            }

            // if (error.response.status === 422) {
            //     errorObject.message = "資料輸入錯誤";
            //     store.dispatch(setError(errorObject));
            // }
        } else if (error.request) {
            // never received a response, or request never left
            // console.log({ "error.request": error.request });
        } else {
            // console.log("Error: error.message", error.message);
        }

        return Promise.reject(error);
    }
);

export default instance;
