import React from "react";
import { Route, Redirect, useLocation } from "react-router";
import useAuth from "../../hooks/useAuth";

const PrivateRoute = ({ children, roles, ...rest }) => {
    const location = useLocation();
    const user = useAuth();

    if (!user.isLogin.current()) {
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    state: { from: location },
                }}
            />
        );
    }

    if (roles && roles.indexOf(user.role) === -1) {
        return <Redirect to={{ pathname: "/" }} />;
    }

    return (
        <Route {...rest}>{children}</Route>
    );
};

export default PrivateRoute;
