import { css } from "styled-components"
import { COLORS } from "../../constants/colors";

export default css`
    display: block;
	background-color: #fff;
	padding: 1rem 0 1.5rem;

	:not(:last-child) {
        border-bottom: 1px solid ${COLORS.greys[2]};
	}
`;