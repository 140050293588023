import React, { useEffect } from "react";
import {
    FaTimes
} from "react-icons/fa";
import {
    Link,
} from "react-router-dom";
import styled from "styled-components";
import { BREAKPOINTS } from "../../constants/breakpoints";
import { COLORS } from "../../constants/colors";
import { ROUTE_PATH } from "../../constants/routePaths";
import useAuth from "../../hooks/useAuth";
import useNotification from "../../hooks/useNotification";
import Menu from "../Menu";

const StyledSideMenu = styled.div`
    width: 100%;
    height: 100%;
    font-size: 1.6rem;
    padding-right: 6rem;
    position: fixed;
    z-index: 1000;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    opacity: ${(props) => (props.isSideMenuShow ? 1 : 0)};
    transform: ${(props) =>
        props.isSideMenuShow ? "translateX(0)" : "translateX(-100%)"};

    @media only screen and (min-width: ${BREAKPOINTS.sm}px) {
        width: 40%;
        min-width: 400px;
        max-width: 600px;
    }
`;

const ScrollContainer = styled.div`
    background-color: ${COLORS.BACKGROUND_COLOR};
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-right: 1px solid #eee;
`;

const ScrollContent = styled.div`
    height: 100%;
    width: calc(100% + 1rem);
    padding-right: 1rem;
    overflow-y: auto;
`;

const SideMenuHeader = styled.div`
    color: white;
    padding: 4rem 1rem;
    font-size: 1.8rem;
    text-align: center;
`;

// const GlobalActionsListContainer = styled.div`
//     display: flex;
//     border-bottom: 1px solid #eee;
//     position: sticky;
//     top: 0px;
//     background-color: #fff;

//     > div {
//         flex: 1;
//     }
// `;

// const GlobalActionContainer = styled.div`
//     cursor: pointer;
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     > div {
//         font-size: 2.4rem;
//         color: ${COLORS.greyBlues[5]};
//     }

//     > span {
//         font-size: 1.4rem;
//     }
// `;

const SideMenuListHeader = styled.div`
    border-top: 3rem solid #ccc;
    background-color: ${COLORS.greyBlues[5]};
    color: white;
    padding: 2rem 1rem;
`;

const CloseButton = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    height: 6rem;
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.BACKGROUND_COLOR};

    > div {
        font-size: 2rem;
    }
`;

const IconContainer = styled.div`
    display: inline-block;
    font-size: 1.8rem;
    margin-right: 1rem;
    transform: translateY(-1px);
    color: ${COLORS.primarys[7]};
`;

const Image = styled.img`
    width: 80%;
    height: auto;
    @media only screen and (min-width: ${BREAKPOINTS.md}px) {
        width: 200px;
        height: 40px;
    }
`;

const SideMenu = ({ isSideMenuShow, onCloseButtonClick }) => {
    // const history = useHistory();
    const user = useAuth();

    const { unReadCount } = useNotification();

    // console.log("notifications", notifications);
    // console.log("unReadNotifications", unReadNotifications);

    useEffect(() => {
        if (isSideMenuShow) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isSideMenuShow]);

    return (
        <StyledSideMenu isSideMenuShow={isSideMenuShow}>
            <ScrollContainer>
                <ScrollContent>
                    <SideMenuHeader>
                        <Image src={process.env.NODE_ENV === "production"
                            ? `${process.env.REACT_APP_IMAGE_HOST}/img/logo.png`
                            : "/img/logo.png"} />
                    </SideMenuHeader>

                    {/* <GlobalActionsListContainer>
                        <GlobalActionContainer
                            onClick={() => {
                                history.push("/");
                            }}
                        >
                            <div>
                                <FaHome />
                            </div>
                            <span>主頁</span>
                        </GlobalActionContainer>
                        {process.env.NODE_ENV !== "production" && (
                            <>
                                <GlobalActionContainer
                                    onClick={() => {
                                        if (user.role === "ORGANIZER") {
                                            history.push("/events-list");
                                        } else if (
                                            user.role === "PARTICIPANT"
                                        ) {
                                            history.push("/reservations-list");
                                        } else {
                                            history.push("/login");
                                        }
                                    }}
                                >
                                    <div>
                                        <FaListAlt />
                                    </div>
                                    {user.role === "ORGANIZER" ? (
                                        <span>管理活動</span>
                                    ) : (
                                        <span>你的預約</span>
                                    )}
                                </GlobalActionContainer>
                                <GlobalActionContainer
                                    onClick={() => {
                                        history.push("/notification");
                                    }}
                                >
                                    <div style={{ position: "relative" }}>
                                        {unReadCount !== 0 && (
                                            <div
                                                style={{
                                                    fontSize: ".5rem",
                                                    color: "yellow",
                                                    position: "absolute",
                                                    right: -8,
                                                    top: 1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    backgroundColor: "red",
                                                    height: "22px",
                                                    width: "22px",
                                                    borderRadius: "50%",
                                                }}
                                            >
                                                {unReadCount >= 99
                                                    ? "99+"
                                                    : unReadCount}
                                            </div>
                                        )}
                                        <FaBell />
                                    </div>
                                    <span>通知</span>
                                </GlobalActionContainer>
                                <GlobalActionContainer
                                    onClick={() => {
                                        history.push("/profile");
                                    }}
                                >
                                    <div>
                                        <FaUserAlt />
                                    </div>
                                    <span>帳戶</span>
                                </GlobalActionContainer>
                            </>
                        )}
                    </GlobalActionsListContainer> */}

                    <Menu>
                        <Menu.Item
                            component={Link}
                            to={`/${ROUTE_PATH.HOME}`}
                            onClick={onCloseButtonClick}
                        >
                            主頁
                        </Menu.Item>
                        {/* <Menu.Item
                            component={Link}
                            to={`/${ROUTE_PATH.FISHING_APP_HOME}`}
                            onClick={onCloseButtonClick}
                        >
                            <IconContainer>
                                <IoFish />
                            </IconContainer>
                            預約釣魚
                        </Menu.Item> */}

                        {/* <Menu.Item
                            component={Link}
                            to={`/${ROUTE_PATH.NOTIFICATION}`}
                            onClick={onCloseButtonClick}
                        >
                            <IconContainer>
                                <FaBell />
                            </IconContainer>
                            通知
                            {unReadCount !== 0 && (
                                <span
                                    style={{
                                        fontSize: "1.1rem",
                                        color: "yellow",
                                        top: 1,
                                        display: "inline-block",
                                        textAlign: "center",
                                        backgroundColor: "red",
                                        // height: "22px",
                                        marginLeft: "5px",
                                        padding: "2px 0",
                                        width: "22px",
                                        borderRadius: "50%",
                                    }}
                                >
                                    {unReadCount >= 99 ? "99+" : unReadCount}
                                </span>
                            )}
                        </Menu.Item> */}
                        <Menu.Item
                            component={Link}
                            to={`${ROUTE_PATH.SEARCH_EVENT_INFO}`}
                            onClick={onCloseButtonClick}
                        >
                            搜尋活動
                        </Menu.Item>
                        <Menu.Item
                            component={Link}
                            to={`${ROUTE_PATH.ABOUT_US}`}
                            onClick={onCloseButtonClick}
                        >
                            關於我們
                        </Menu.Item>
                        <Menu.Item
                            component={Link}
                            to={`${ROUTE_PATH.CONTACT_US}`}
                            onClick={onCloseButtonClick}
                        >
                            聯絡我們
                        </Menu.Item>

                        {/* <Menu.Item
                            component={Link}
                            to="/search-user"
                            onClick={onCloseButtonClick}
                        >
                            <IconContainer>
                                <FaSearch />
                            </IconContainer>
                            搜尋用戶
                        </Menu.Item> */}

                        {/* {user.isLogin.current() && user.isParticipant() && (
                            <>
                                <Menu.Item
                                    component={Link}
                                    to={`/${ROUTE_PATH.RESERVATIONS_LIST}`}
                                    onClick={onCloseButtonClick}
                                >
                                    你的預約
                                </Menu.Item>
                            </>
                        )} */}

                        {/* {user.isLogin.current() && user.isOrganizer() && (
                            <>
                                <Menu.Item
                                    component={Link}
                                    to={`/${ROUTE_PATH.EVENTS_LIST}`}
                                    onClick={onCloseButtonClick}
                                >
                                    管理活動
                                </Menu.Item>
                                <Menu.Item
                                    component={Link}
                                    to={`/${ROUTE_PATH.SHIPS_LIST}`}
                                    onClick={onCloseButtonClick}
                                >
                                    管理船隻
                                </Menu.Item>
                            </>
                        )} */}

                        {/* <SideMenuListHeader>資訊</SideMenuListHeader>

                        <Menu>
                            <Menu.SubMenu title="釣具店資料">
                                <Menu.SubMenu title="貨品類型">
                                    {Object.entries(STORE_PRODUCT_TYPES).map(
                                        ([key, type]) => (
                                            <Menu.Item
                                                key={key}
                                                component={Link}
                                                to={{
                                                    pathname: "/store-info",
                                                    search: `?search=${type}`,
                                                }}
                                                onClick={onCloseButtonClick}
                                            >
                                                {type}
                                            </Menu.Item>
                                        )
                                    )}
                                </Menu.SubMenu>
                                <Menu.SubMenu title="地區">
                                    {Object.entries(DISTRICTS).map(
                                        ([bigDistrcit, districts]) => {
                                            return Object.entries(
                                                districts
                                            ).map(([key, district]) => (
                                                <Menu.Item
                                                    key={key}
                                                    component={Link}
                                                    to={{
                                                        pathname: "/store-info",
                                                        search: `?search=${district}`,
                                                    }}
                                                    onClick={onCloseButtonClick}
                                                >
                                                    {district}
                                                </Menu.Item>
                                            ));
                                        }
                                    )}
                                </Menu.SubMenu>
                            </Menu.SubMenu>
                            <Menu.SubMenu title="主辦單位資料">
                                <Menu.SubMenu title="主要活動類型">
                                    {Object.entries(EVENT_TYPES).map(
                                        ([key, type]) => (
                                            <Menu.Item
                                                key={key}
                                                component={Link}
                                                to={{
                                                    pathname: "/organizer-info",
                                                    search: `search=${type}`,
                                                }}
                                                onClick={onCloseButtonClick}
                                            >
                                                {type}
                                            </Menu.Item>
                                        )
                                    )}
                                </Menu.SubMenu>
                                <Menu.SubMenu title="主要活動海域">
                                    {Object.entries(FISHING_LOCATIONS)
                                        .filter(
                                            ([key, type]) => key !== "OTHER"
                                        )
                                        .map(([key, type]) => (
                                            <Menu.Item
                                                key={key}
                                                component={Link}
                                                to={{
                                                    pathname: "/organizer-info",
                                                    search: `search=${type}`,
                                                }}
                                                onClick={onCloseButtonClick}
                                            >
                                                {type}
                                            </Menu.Item>
                                        ))}
                                </Menu.SubMenu>
                            </Menu.SubMenu>
                        </Menu>

                        <SideMenuListHeader>提供資料</SideMenuListHeader>
                        <Menu.Item
                            component={Link}
                            to="/provide-store-info"
                            onClick={onCloseButtonClick}
                        >
                            提供釣具店資訊
                        </Menu.Item>
                        <Menu.Item
                            component={Link}
                            to="/provide-organizer-info"
                            onClick={onCloseButtonClick}
                        >
                            提供主辦單位資訊
                        </Menu.Item> */}
                    </Menu>
                </ScrollContent>
                <CloseButton onClick={onCloseButtonClick}>
                    <div>
                        <FaTimes />
                    </div>
                </CloseButton>
            </ScrollContainer>
        </StyledSideMenu>
    );
};

export default SideMenu;
