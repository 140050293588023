import axios from "axios";
import qs from "qs";
import wfsAPIBase from "./base";

// signup with credentials
export const signup = async (data) => {
    /**
     * { username: "...", password: "...", ... }
     */

    return await wfsAPIBase.post(`/signup`, data);
};

// login with credentials and get uesrinfo and tokens
export const login = async (data) => {
    /**
     * { username: "...", password: "..." }
     */

    return await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/login`, data, {
        withCredentials: true,
    });
};

// get new access token with refresh token set in cookie
export const getNewAccessToken = async () => {
    return await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/api/v1/token`,
        {},
        {
            withCredentials: true,
        }
    );
};

export const resendEmailToken = async () => {
    return await wfsAPIBase.post("/email-token");
};

// email confirmation
export const confirmEmail = async (emailToken) => {
    return await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/api/v1/email-confirmation/${emailToken}`
    );
};

// update password with old password verify
export const updatePassword = async (data) => {
    return await wfsAPIBase.put(`/password`, data);
};

// reset password
export const resetPassword = async (data) => {
    return await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/api/v1/password-reset`,
        data
    );
};

// reset password re enter password
export const resetPasswordReenterPassword = async (
    passwordResetToken,
    data
) => {
    return await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/api/v1/password-reset-reenter-password/${passwordResetToken}`,
        data
    );
};

// remove refresh token from server database
export const logout = async (data) => {
    return await axios.delete(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/logout`, {
        withCredentials: true,
    });
};

export const getCode = async (query = {}) => {
    return await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/get-code`, {
        params: query,
        paramsSerializer: (params) => {
            return qs.stringify(params);
        },
    });
};

export const verifyCode = async (query = {}) => {
    return await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/verify-code`, {
        params: query,
        paramsSerializer: (params) => {
            return qs.stringify(params);
        },
    });
};
