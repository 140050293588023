import React from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import * as wfsUserProfileAPI from "../api/wfsAPI/user-profile";
import { COLORS } from "../constants/colors";
import { BREAKPOINTS } from "../constants/breakpoints";
import ShipProfileBox from "../components/ShipProfileBox";
import RowContainer from "../components/RowContainer";
import Heading2 from "../components/Heading2";
import FormInputButton from "../components/form/FormInputButton";
import { ROUTE_PATH } from "../constants/routePaths";

const Container = styled.div`
    @media only screen and (min-width: 1200px) {
        max-width: 1200px;
        margin: 0 auto;
    }
`;

const ActionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    padding: 1rem;
    & button {
        font-size: 1.6rem;
        padding: 1rem;
    }
`;

const ShipProfileContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: ${COLORS.BACKGROUND_COLOR};
    gap: 4px;
    padding: 1rem 1rem;
    margin-bottom: 2rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1rem 0.4rem;
    border-radius: 2px;

    > a {
        flex-basis: 32.5%;
    }

    @media only screen and (min-width: ${BREAKPOINTS.md}px) {
        > a {
            flex-basis: 24.5%;
        }
    }

    @media only screen and (min-width: ${BREAKPOINTS.lg}px) {
        > a {
            flex-basis: 16.3%;
        }
    }
`;

const ShipsList = () => {
    const fetchUserProfile = async () => {
        const result = await wfsUserProfileAPI.getUserProfile();
        return result.data;
    };
    const { data: userProfile } = useQuery(["userProfile"], () =>
        fetchUserProfile()
    );

    return (
        <Container>
            <Heading2>船隻管理</Heading2>
            <ActionContainer>
                <Link to={`/${ROUTE_PATH.SHIP_MANAGEMENT_FORM}`}>
                    <FormInputButton>新增船隻</FormInputButton>
                </Link>
            </ActionContainer>
            <ShipProfileContainer>
                {userProfile && userProfile.shipProfiles.length !== 0 ? (
                    userProfile.shipProfiles.map((shipProfile) => {
                        return (
                            <Link
                                key={shipProfile._id}
                                to={`/ship-management-form/${shipProfile._id}`}
                            >
                                <ShipProfileBox {...shipProfile} />
                            </Link>
                        );
                    })
                ) : (
                    <RowContainer
                        style={{
                            width: "100%",
                            paddingLeft: "1rem",
                        }}
                    >
                        找不到紀錄
                    </RowContainer>
                )}
            </ShipProfileContainer>
        </Container>
    );
};

export default ShipsList;
