export const phoneFormat = (phone) => {
	return phone.slice(0, 4) + " " + phone.slice(4);
};

export const currencyFormat = (price) => {
	const formatter = new Intl.NumberFormat("zh-HK", {
		style: "currency",
		currency: "HKD",
	});

	return formatter.format(price).replace("HK", "");
};

export const transformFilters = (filters) => {
	return Object.fromEntries(
		Object.entries(filters).filter(([field, value]) => {
			return value !== false && value !== undefined;
		})
	);
};

// Generate unique id
export const uuid = () => {
	let dt = new Date().getTime();

	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
};
