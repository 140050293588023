export const ROUTE_PATH = {
	/**
	 * Public
	 */
	HOME: "",
	ORGANIZER_INFO: "organizer-info",
	STORE_INFO: "store-info",
	PROVIDE_ORGANIZER_INFO: "provide-organizer-info",
	PROVIDE_STORE_INFO: "provide-store-info",
	ABOUT_US: "about-us",
	CONTACT_US: "contact-us",
	TERMS_AND_CONDITIONS: "terms-and-conditions",
	TERMS_OF_USE: "terms-of-use",
	PRIVACY: "privacy",
	BENEFITS_MEMBER: "member-benefits",
	BENEFITS_ORGANIZER: "organizer-benefits",
	EVENT_INFO: "event-info",
	FISHING_APP_HOME: "fishing-app-home",
	SEARCH_EVENT_INFO: "search-event-info",
	SEARCH_USER: "search-user",

	OPERATION_FLOW: "operation-flow",
	RESERVATION_FLOW: "reservation-flow",
	CANCEL_FLOW: "cancel-flow",

    IMPORTANT_DATES: "important-dates",

	CHECK_EMAIL: "check-email",
	EMAIL_CONFIRMATION: "email-confirmation",

	PROFILE: "profile",
    SHIP_INFO: "ship-info",
	FISHING_LOCATION_PROFILE_INFO: "event-template",

	/**
	 * Auth
	 */
	LOGIN: "login",
	SIGNUP: "signup",
    PASSWORD_RESET: "reset-password",
    PASSWORD_RESET_REENTER_PASSWORD: "reset-password-update",
    CREATE_STRIPE_ACCOUNT: "create-stripe-account",

	/**
	 * Organizer
	 */
	SHIP_MANAGEMENT_FORM: "ship-management-form",
	FISHING_LOCATION: "fishing-location",
	SHIPS_LIST: "ships-list",

	EVENTS_LIST: "events-list",
	EVENT_MANAGEMENT: "event-management",
	CREATE_EVENT: "create-event",

    ORGANIZER_INVOICE: "organizer-invoice",

	/**
	 * Participant
	 */
	PARTICIPANT_RESERVATION_FORM: "participant-reservation-form",
	RESERVATIONS_LIST: "reservations-list",
	PARTICIPANT_RESERVATION: "participant-reservation",

	/**
	 * Participant and Organizer
	 */
	EDIT_PROFILE: "edit-profile",
	NOTIFICATION: "notification",
};
