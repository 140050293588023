import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    signup as signupAction,
    login as loginAction,
    logout as logoutAction,
    getNewAccessToken,
    selectAccessToken,
    selectRefreshToken,
    selectUser,
    selectAuthStatus,
    authSucceeded
} from "../features/user/userSlice";
import wfsAPIBase from "../api/wfsAPI/base";
import { getUnReadCount } from "../features/notification/notificationSlice";

const useAuth = () => {
    const dispatch = useDispatch();

    const authStatus = useSelector(selectAuthStatus);
    const user = useSelector(selectUser);
    const accessToken = useSelector(selectAccessToken);
    const refreshToken = useSelector(selectRefreshToken);

    const isLogin = useRef(() => {});
    isLogin.current = () => user._id !== null;
    const isOrganizer = () => user.role === "ORGANIZER";
    const isParticipant = () => user.role === "PARTICIPANT";

    const signup = (data) => {
        return dispatch(signupAction(data))
            .unwrap()
            .then((result) => {
                wfsAPIBase.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${result.accessToken}`;

                return result;
            });
    };

    const login = (data) => {
        return dispatch(loginAction(data))
            .unwrap()
            .then((result) => {
                wfsAPIBase.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${result.accessToken}`;
                dispatch(getUnReadCount());

                return result;
            });
    };

    const loginIfToken = useRef(() => {});

    loginIfToken.current = () => {
        return dispatch(getNewAccessToken())
            .unwrap()
            .then((result) => {
                const { expiresIn } = result;

                wfsAPIBase.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${result.accessToken}`;

                setTimeout(() => {
                    loginIfToken.current();
                }, (expiresIn - 30) * 1000);
                return result;
            });
    };

    const setUserLoginInfo = useRef(() => {});
    setUserLoginInfo.current = (data) => {
        return dispatch(authSucceeded(data));
    }

    const logout = async () => {
        dispatch(logoutAction());
    };

    return {
        authStatus,
        ...user,
        accessToken,
        refreshToken,
        isLogin,
        isOrganizer,
        isParticipant,
        signup,
        loginIfToken,
        login,
        setUserLoginInfo,
        logout,
    };
};

export default useAuth;
