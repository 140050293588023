import { css } from "styled-components";
import { COLORS } from "../../../constants/colors";

export default css`
    display: flex;
    align-items: center;
    justify-content: center;

    border: ${props => props.invert ? `1px solid ${COLORS.primarys[5]}` : "none"};
    border-radius: 3px;
    color: ${props => props.invert ? `${COLORS.primarys[5]}` : "white"};
    font-weight: 500;
    letter-spacing: .1em;
    background-color: ${props => props.invert ? 
                                    props.disabled ? COLORS.greys[2] : `white` : 
                                    props.disabled ? COLORS.greys[2] : COLORS.primarys[5]};
    cursor: pointer;

    font-size: 1.6rem;
    line-height: 1.6rem;
    padding: .8rem 1.6rem;

    .btn:active, .btn:focus {
        outline: none;
        box-shadow: none;
    }
`;