import { useState, useCallback } from "react";
import createNamedContext from "./createNamedContext";
import Spinner from "react-bootstrap/Spinner";

const ModalContext = createNamedContext("modal");

export const ModalProvider = ({ children }) => {
	const [isShow, setIsShow] = useState(false);
	const [title, setTitle] = useState("");
	const [body, setBody] = useState(() => {
		return null;
	});
	const [actions, setActions] = useState([]);

	const show = useCallback(() => setIsShow(true), [setIsShow]);
	const close = useCallback(() => setIsShow(false), [setIsShow]);

	const showLoading = useCallback(() => {
		setTitle("正在處理中");
		setBody(() => {
			return (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						padding: "4rem 0",
					}}
				>
					<Spinner
						style={{
							height: "3rem",
							width: "3rem",
						}}
						animation="border"
						variant="primary"
					/>
					<span
						style={{
							fontSize: "1.6rem",
							marginTop: ".4rem",
						}}
					>
						處理中...
					</span>
				</div>
			);
		});
		setIsShow(true);
	}, [setIsShow]);

	const contextValue = {
		isShow,
		title,
		body,
		actions,
		show,
		showLoading,
		close,
		setTitle,
		setBody,
		setActions,
	};

	return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};

export default ModalContext;
