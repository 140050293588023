import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    showGlobalNotification,
    hideGlobalNotification,
    getLatestGlobalNotification,
    selectGlobalNotification,
} from "../features/notification/notificationSlice";

const useGlobalNotification = () => {
    const dispatch = useDispatch();

    const getLatestNotificationRef = useRef(() => {});

    const globalNotification = useSelector(selectGlobalNotification);

    getLatestNotificationRef.current = () =>
        dispatch(getLatestGlobalNotification());

    const show = () => {
        dispatch(showGlobalNotification());
    };

    const hide = () => {
        dispatch(hideGlobalNotification());
    };

    return {
        ...globalNotification,
        getLatestNotificationRef,
        show,
        hide,
    };
};

export default useGlobalNotification;
