import wfsAPIBase from "./base";
import qs from "qs";

const resourceName = "notifications";

/**
 *
 * @param {*} data: {"sender": senderId, "receiver": receiverId, "message": message}
 * @returns
 */
export const createNotification = async (data) => {
    return await wfsAPIBase.post(`/${resourceName}/`, data);
};

export const getNotifications = async (userId) => {
    return await wfsAPIBase.get(`/${resourceName}/${userId}/`);
};

export const getUnReadCount = async () => {
    return await wfsAPIBase.get(`/${resourceName}/unReadCount`);
};

export const getFiniteNotifications = async (query = {}) => {
    return await wfsAPIBase.get(`/${resourceName}`, {
        params: query,
        paramsSerializer: (params) => {
            return qs.stringify(params);
        },
    });
};

/**
 *
 * @param {*} userId
 * @param {*} data : {"notificationsIds: ["notificationsId1", ... ,]}
 * @returns
 */
export const updateNotifications = async (userId, data) => {
    return await wfsAPIBase.post(`/${resourceName}/${userId}/`, data);
};
