export const USER_ROLES = {
	PARTICIPANT: "會員",
    ORGANIZER: "主辦單位"
};

// !!! Do strict checking in server, this "status" is for display purpose
export const USER_ACCOUNT_STATUS = {
    PROVISIONED: "PROVISIONED",
    ACTIVE: "ACTIVE",
    RECOVERY: "RECOVERY"
};