import React, { useEffect } from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { COLORS } from "../constants/colors";
import useGlobalNotification from "../hooks/useGlobalNotification";

const Container = styled.div`
    background-color: ${COLORS.yellows[5]};
    max-height: ${(props) => (props.isShow ? "100px" : "0px")};
    overflow-y: hidden;
    transition: max-height 0.3s;
`;

const ContentContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    font-size: 1.6rem;
    padding: 1rem 2rem;
    text-align: center;
`;

const Dismissible = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    padding-right: .4rem;
    color: ${COLORS.greys[5]};
    cursor: pointer;
`;

const GlobalNotification = ({ children, ...props }) => {
    const { isShow, hide, message, getLatestNotificationRef } =
        useGlobalNotification();

    useEffect(() => {
        getLatestNotificationRef.current();
    }, [getLatestNotificationRef]);

    return (
        <Container isShow={isShow} {...props}>
            <ContentContainer>
                {message}
                <Dismissible onClick={hide}>
                    <FaTimes />
                </Dismissible>
            </ContentContainer>
        </Container>
    );
};

export default GlobalNotification;
