import wfsAPIBase from "./base";
import qs from "qs";

export const resourceName = "user-profile";

export const getUserProfileById = async (id) => {
	return await wfsAPIBase.get(`/${resourceName}/${id}`);
};

export const getUserProfile = async () => {
	return await wfsAPIBase.get(`/${resourceName}`);
};

export const updateUserProfile = async (data) => {
	return await wfsAPIBase.put(`/${resourceName}/`, data);
};

export const getUsersByQuery = async (query = {}) => {
	return await wfsAPIBase.get(`/${resourceName}/search`, {
		params: query,
		paramsSerializer: (params) => {
			return qs.stringify(params);
		},
	});
};
