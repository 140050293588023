import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
// import moment from "moment";
// import "moment/locale/zh-hk";
import * as wfsNotificationsAPI from "../../api/wfsAPI/notifications";
import * as wfsGlobalNotificationsAPI from "../../api/wfsAPI/global-notifications";

import isBefore from "date-fns/isBefore";
import isEqual from "date-fns/isEqual";
// import { compareTime } from "../../utils/datetimeUtils";

// moment.locale("zh-hk");

/**
 *  Initial state
 */
const notificationsAdapter = createEntityAdapter({
    selectId: (notification) => notification._id,
    sortComparer: (a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        // return compareTime(dateB, dateA);
        // const dateA = moment(a.createdAt);
        // const dateB = moment(b.createdAt);

        if (isBefore(dateA, dateB)) {
            return 1;
        } else if (isEqual(dateA, dateB)) {
            return 0;
        } else {
            return -1;
        }
    },
});

// extract later
const errorResponse = (err) => {
    return {
        status: err.response.status,
        data: err.response.data,
        headers: err.response.headers,
    };
};

export const getNotifications = createAsyncThunk(
    "notification",
    // async (userId, { rejectWithValue }) => {
    //     try {
    //         // await wfsAuthAPI.logout();
    //         return await (
    //             await wfsNotificationsAPI.getNotifications(userId)
    //         ).data.data;
    //     } catch (err) {
    //         return rejectWithValue(errorResponse(err));
    //     }
    // }

    async ({ page, perPage = 10 }, { rejectWithValue }) => {
        try {
            const result = await wfsNotificationsAPI.getFiniteNotifications({
                page,
                perPage,
            });
            return result.data.data;
        } catch (err) {
            return rejectWithValue(errorResponse(err));
        }
    }
);

export const getUnReadCount = createAsyncThunk(
    "notification/getUnReadCount",
    async (data, { rejectWithValue }) => {
        try {
            const result = await wfsNotificationsAPI.getUnReadCount();

            return result.data.data;
        } catch (err) {
            return rejectWithValue(errorResponse(err));
        }
    }
);

export const getLatestGlobalNotification = createAsyncThunk(
    "notification/getLastestGlobalNotification",
    async (data, { rejectWithValue }) => {
        try {
            const result =
                await wfsGlobalNotificationsAPI.getLatestNotification();
            if (!result.data.data) {
                return rejectWithValue({ message: "No global notification" });
            }
            return result.data.data;
        } catch (err) {
            return rejectWithValue(errorResponse(err));
        }
    }
);

// reducers
const loading = (state) => {
    state.status = "loading";
};

export const notificationSlice = createSlice({
    name: "notification",
    initialState: notificationsAdapter.getInitialState({
        status: "idle",
        page: 0,
        totalPageCount: 0,
        globalNotification: {
            isShow: false,
            message: "歡迎來到水樂坊",
            status: "idle",
        },
        unReadCount: 0,
    }),
    reducers: {
        addOne: (state, action) => {
            notificationsAdapter.addOne(state, action.payload);
        },
        upsertMany: notificationsAdapter.upsertMany,
        addOneUnReadCount: (state) => {
            state.unReadCount = state.unReadCount + 1;
        },
        updateUnReadCount: (state, action) => {
            state.unReadCount = Math.max(state.unReadCount - action.payload, 0);
        },
        resetNotification: () =>
            notificationsAdapter.getInitialState({
                status: "idle",
                page: 0,
                totalPageCount: 0,
                globalNotification: {
                    isShow: true,
                    message: "歡迎來到水樂坊",
                    status: "idle",
                },
                unReadCount: 0,
            }),

        // global notification
        showGlobalNotification: (state) => {
            state.globalNotification.isShow = true;
        },
        hideGlobalNotification: (state) => {
            state.globalNotification.isShow = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // .addCase(getNotifications.pending, loading)
            // .addCase(getNotifications.fulfilled, (state, action) => {
            //     notificationsAdapter.setAll(state, action.payload);
            // })
            .addCase(getNotifications.pending, loading)
            .addCase(getNotifications.fulfilled, (state, action) => {
                notificationsAdapter.addMany(
                    state,
                    action.payload.notifications
                );
                state.page = state.page + 1;
                state.totalPageCount = action.payload.totalPageCount;
            })
            .addCase(getLatestGlobalNotification.pending, (state) => {
                state.globalNotification.status = "loading";
            })
            .addCase(getLatestGlobalNotification.fulfilled, (state, action) => {
                state.globalNotification.status = "idle";
                state.globalNotification.message = action.payload.message;
            })
            .addCase(getLatestGlobalNotification.rejected, (state, action) => {
                state.globalNotification.status = "idle";
            })
            .addCase(getUnReadCount.pending, loading)
            .addCase(getUnReadCount.fulfilled, (state, action) => {
                state.unReadCount = action.payload.unReadCount;
            });
    },
});

/**
 *  Actions
 */
export const {
    addOne,
    upsertMany,
    showGlobalNotification,
    hideGlobalNotification,
    updateUnReadCount,
    addOneUnReadCount,
    resetNotification,
} = notificationSlice.actions;

/**
 * default export reducer
 */
export default notificationSlice.reducer;

/**
 * selectors
 */
export const { selectAll: selectNotifications, sele } =
    notificationsAdapter.getSelectors((state) => state.notification);
export const selectNotificationPage = (state) => state.notification.page;
export const selectNotificationsTotalPageCount = (state) =>
    state.notification.totalPageCount;
export const selectNotificationsUnReadCount = (state) =>
    state.notification.unReadCount;
export const selectGlobalNotification = (state) =>
    state.notification.globalNotification;
