import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaBell } from "react-icons/fa";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { BREAKPOINTS } from "../../constants/breakpoints";
import { COLORS } from "../../constants/colors";
import { ROUTE_PATH } from "../../constants/routePaths";
import useAuth from "../../hooks/useAuth";
import useNotification from "../../hooks/useNotification";
import Container from "../Container";
import GlobalNotification from "../GlobalNotification";

const HeaderContainer = styled.div`
    position: ${(props) => (props.fixedPosition ? "fixed" : "sticky")};
    top: 0;
    z-index: 200;
`;

const NavbarContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background-color: ${(props) => (props.visible ? "white" : "transparent")};
    font-size: 1.6rem;
    border-bottom: ${(props) =>
        props.visible ? `1px solid ${COLORS.greys[0]}` : "transparent"};
    transition: all 0.3s;
    width: 100%;
    box-shadow: 0 24px 40px 0 #4f4a4f1A;

    opacity: ${(props) => (props.show ? 1 : 0)};
    visibility: ${(props) => (props.show ? "show" : "hidden")};
`;

const IconButton = styled.div`
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    @media only screen and (min-width: ${BREAKPOINTS.md}px) {
        display: none;
    }
`;

const StyledSideBarIcon = styled.div`
    width: 2rem;
    height: 1.5rem;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;

    & span {
        display: block;
        position: absolute;
        height: 0.3rem;
        width: 100%;
        background: black;
        border-radius: 0.3rem;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
    }

    & span:nth-child(1) {
        top: 0px;
    }

    & span:nth-child(2) {
        top: 0.6rem;
    }

    & span:nth-child(3) {
        top: 1.2rem;
    }
`;
const SideBarIcon = ({ isSidebarOpen }) => {
    return (
        <StyledSideBarIcon isSidebarOpen={isSidebarOpen}>
            <span></span>
            <span></span>
            <span></span>
        </StyledSideBarIcon>
    );
};

const Brand = styled(Link)`
    padding: 1rem 0;
    margin-right: 1rem;
    transition: opacity 0.3s visibility 0.3s;
    opacity: ${(props) => (props.show === "true" ? 1 : 0)};
    visibility: ${(props) => (props.show === "true" ? "visible" : "hidden")};
`;

const Image = styled.img`
    width: 15rem;
    height: 3rem;
    @media only screen and (min-width: ${BREAKPOINTS.md}px) {
        width: 200px;
        height: 40px;
    }
`;

const Toggle = styled(NavDropdown)`
    background-color: ${COLORS.greys[1]};
    border-radius: 20px;
    ::after {
        display: none;
    }
    &:hover{
        background: ${COLORS.greys[2]};
    }
`;

const NavLink1 = styled(NavLink)`
    transition: all 0.3s;
    align-items: center;
    height: 100%;
    &:hover{
        background: ${COLORS.greys[0]};
    }
    display: none;
    @media only screen and (min-width: ${BREAKPOINTS.md}px) {
        display: flex;
    }
`;

const Navbar = ({ show, visible, isSidebarOpen, onSideBarIconClick }) => {
    const history = useHistory();
    const location = useLocation();

    const { unReadCount, resetNotificationRef } = useNotification();

    const user = useAuth();

    const handleSelect = (eventKey) => {
        // alert(`selected ${eventKey}`);

        if (eventKey === "notification") {
            history.push("/notification");
        }

        if (eventKey === "profile") {
            history.push("/profile");
        }

        if (eventKey === "login") {
            history.push("/login");
        }

        if (eventKey === "signup") {
            history.push({
                pathname: `/${ROUTE_PATH.TERMS_AND_CONDITIONS}`,
                search: "?isSignUp=true",
            });
        }

        if (eventKey === "logout") {
            user.logout();
            resetNotificationRef.current();
            history.replace("/login");
        }

        if (eventKey === "edit") {
            history.push("/edit-profile");
        }
    };

    return (
        <HeaderContainer>
            <GlobalNotification>{/* SOMETHING */}</GlobalNotification>
            <NavbarContainer
                show={show}
                visible={(location.pathname !== "/" || visible).toString()}
            >
                <Container style={{ justifyContent: "space-between" }}>
                    <IconButton onClick={onSideBarIconClick}>
                        <SideBarIcon isSidebarOpen={isSidebarOpen} />
                    </IconButton>
                    <Nav
                        onSelect={handleSelect}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <Brand
                            to={`/${ROUTE_PATH.HOME}`}
                            show={show.toString()}
                        >
                            <Image src={process.env.NODE_ENV === "production"
                                ? `${process.env.REACT_APP_IMAGE_HOST}/img/logo.png`
                                : "/img/logo.png"} />
                        </Brand>
                        <>
                            <NavLink1
                                style={{
                                    fontSize: "1.6rem",
                                    padding: "10px 20px",
                                }}
                                to={{
                                    pathname: `/${ROUTE_PATH.SEARCH_EVENT_INFO}`,
                                }}
                            >
                                搜尋活動
                            </NavLink1>
                            <NavLink1
                                style={{
                                    fontSize: "1.6rem",
                                    padding: "10px 20px",
                                }}
                                to={{
                                    pathname: `/${ROUTE_PATH.ABOUT_US}`,
                                }}
                            >
                                關於我們
                            </NavLink1>
                            <NavLink1
                                style={{
                                    fontSize: "1.6rem",
                                    padding: "10px 20px",
                                }}
                                to={{
                                    pathname: `/${ROUTE_PATH.CONTACT_US}`,
                                }}
                            >
                                聯絡我們
                            </NavLink1>
                            {/* <NavLink1
                                style={{
                                    fontSize: "1.6rem",
                                    padding: "10px 20px",
                                }}
                                to={{
                                    pathname: '/products',
                                }}
                            >
                                常見問題
                            </NavLink1> */}
                            {user.isLogin.current() && (
                                <Nav.Item
                                    style={{
                                        marginLeft: "auto",
                                        fontSize: "1.8rem",
                                        marginRight: ".4rem",
                                    }}
                                >
                                    <Nav.Link eventKey="notification">
                                        <div style={{ position: "relative" }}>
                                            {unReadCount !== 0 && (
                                                <div
                                                    style={{
                                                        fontSize: "1rem",
                                                        color: "yellow",
                                                        position: "absolute",
                                                        right: -11,
                                                        top: -6,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                        backgroundColor: "red",
                                                        height: "22px",
                                                        width: "22px",
                                                        borderRadius: "50%",
                                                    }}
                                                >
                                                    {unReadCount >= 99
                                                        ? "99+"
                                                        : unReadCount}
                                                </div>
                                            )}
                                            <FaBell />
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            <Nav.Item
                                style={{
                                    marginLeft: user.isLogin.current()
                                        ? ""
                                        : "auto",
                                    fontSize: "1.7rem",
                                }}
                            >
                                {user.isLogin.current() ? (
                                    <Toggle
                                        title={<FontAwesomeIcon icon={faUser} />}
                                        id="nav-dropdown"
                                    >
                                        <NavDropdown.Item
                                            style={{
                                                fontSize: "1.6rem",
                                                padding: "10px",
                                            }}
                                            eventKey={"profile"}
                                        >
                                            {user.nickname}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item
                                            style={{
                                                fontSize: "1.6rem",
                                                padding: "10px",
                                            }}
                                            eventKey={"edit"}
                                        >
                                            編輯個人檔案
                                        </NavDropdown.Item>
                                        {/* <NavDropdown.Item
                                                style={{ fontSize: "1.6rem" }}
                                                eventKey={"setting"}
                                            >
                                                設定
                                            </NavDropdown.Item> */}
                                        <NavDropdown.Item
                                            style={{
                                                fontSize: "1.6rem",
                                                padding: "10px",
                                            }}
                                            eventKey={"logout"}
                                        >
                                            登出
                                        </NavDropdown.Item>
                                    </Toggle>
                                ) : (
                                    <div style={{
                                        display: "flex",
                                    }}>
                                        <Nav.Link
                                            style={{
                                                fontSize: "1.6rem",
                                                padding: "10px 20px",
                                            }}
                                            eventKey={"signup"}
                                        >
                                            註冊
                                        </Nav.Link>
                                        <Nav.Link
                                            style={{
                                                fontSize: "1.6rem",
                                                padding: "10px 20px",
                                                color: COLORS.greys[1],
                                                backgroundColor: COLORS.greyBlues[5],
                                            }}
                                            className="rounded-pill"
                                            eventKey={"login"}
                                        >
                                            登入
                                        </Nav.Link>
                                    </div>
                                )}
                            </Nav.Item>
                        </>
                    </Nav>
                </Container>
            </NavbarContainer>
        </HeaderContainer>
    );
};

export default Navbar;
