import history from "./history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { configureStore } from "@reduxjs/toolkit";
import errorReducer from "../features/error/errorSlice";
import userReducer from "../features/user/userSlice";
import notificationReducer from "../features/notification/notificationSlice";

export default configureStore({
	reducer: {
		router: connectRouter(history),
		error: errorReducer,
		user: userReducer,
		notification: notificationReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history)),
});
