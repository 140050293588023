export const COLORS = {
    RESERVATION_STATUS: {
        AVAILABLE: "#fff",
        RESERVED: "#C1C1C1",
        WAITING_FOR_CONFIRM: "#F77272",
        CONFIRMED_RESERVATION: "#FFA803",
        WAITING_FOR_PAYMENT: "#ECFF03",
        E_PAID: "#4AAF00",
        CASH_PAID: "#577DFF",
        WAITING_FOR_CANCEL: "#B07BFF",
        NO_SHOW: "#ECFF03",
    },

    BACKGROUND_COLOR: "#fdfdfd",

    EVENT_STATUS: {
        NOT_FORMED: "#FFA803",
        FORMED: "#F8F4A6",
        FULL: "#F8F4A6",
        // CHARTER: "#F194B4",
        // CANCEL: "#EF7A85",
        FINISHED: "#B2C3FF",
    },

    primarys: [
        "#EEF2FF",
        "#D0DBFF",
        "#B2C3FF",
        "#94ACFF",
        "#7594FF",
        "#577DFF",
        "#4361C6",
        "#30458C",
        "#1C2953",
        "#090D1A",
    ],

    dangers: [
        "#FEF1F1",
        "#FDD8D8",
        "#FBBEBE",
        "#FAA5A5",
        "#F88B8B",
        "#F77272",
        "#BF5858",
        "#883F3F",
        "#502525",
        "#190B0B",
    ],

    riceYellows: [
        "#FFFEFD",
        "#FEFCF8",
        "#FEFAF4",
        "#FEF9EF",
        "#FDF7EB",
        "#FDF5E6",
        "#C4BEB2",
        "#8B877F",
        "#52504B",
        "#191917",
    ],

    yellows: [
        "#FDFFE6",
        "#FAFFB8",
        "#F6FF8B",
        "#F3FF5E",
        "#EFFF30",
        "#ECFF03",
        "#B7C602",
        "#828C02",
        "#4D5301",
        "#181A00",
    ],

    greens: [
        "#EDF7E6",
        "#CCE9B8",
        "#ACDA8A",
        "#8BCC5C",
        "#6BBD2E",
        "#4AAF00",
        "#398800",
        "#296000",
        "#183900",
        "#071200",
    ],

    purples: [
        "#F7F2FF",
        "#E9DAFF",
        "#DBC2FF",
        "#CCABFF",
        "#BE93FF",
        "#B07BFF",
        "#885FC6",
        "#61448C",
        "#392853",
        "#120C1A",
    ],

    greys: [
        "#F1F1F1",
        "#D7D7D7",
        "#BDBDBD",
        "#A4A4A4",
        "#8A8A8A",
        "#707070",
        "#575757",
        "#3E3E3E",
        "#242424",
        "#0B0B0B",
    ],
    greyBlues: [
        "#ECEDF2",
        "#CACDDA",
        "#A7ADC2",
        "#858DAA",
        "#626D92",
        "#404D7A",
        "#323C5F",
        "#232A43",
        "#151928",
        "#06080C",
    ],
};
