import React from "react";
import styled from "styled-components";
import FormInputButton from "../components/form/FormInputButton";
import { COLORS } from "../constants/colors";

const Brand = styled.div`
    display: inline-block;
    font-size: 1.8rem;
    padding: 0.4rem 0.8rem;
    margin: 1rem 2rem;
    background-color: ${COLORS.greyBlues[5]};
    border-radius: 2px;
    color: white !important;
    transition: all 0.3s;
    font-weight: 400;
    cursor: pointer;

    @media only screen and (min-width: 1200px) {
        display: block;
        max-width: 1200px;
        margin: 0 auto;
    }
`

const Container = styled.div`
    margin: 1rem 2rem;

    @media only screen and (min-width: 1200px) {
        max-width: 1200px;
        margin: 0 auto;
    }
`;

const ContentContainer = styled.div`
    margin: 2rem 0;
    padding: 2rem;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #fff;
`;

const Heading = styled.h1`
    font-weight: 600;
    font-size: 3.4rem;
`;

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
        <>
            <Brand onClick={() => window.location.href = "/"}>WaterFun Square 水樂坊</Brand>
            <Container>
                <ContentContainer>
                    <Heading>出現了未知的錯誤</Heading>
                    {process.env.NODE_ENV === "development" && (
                        <pre>{error.message}</pre>
                    )}
                    <FormInputButton onClick={() => window.location.href = "/"}>回到主頁</FormInputButton>
                </ContentContainer>
            </Container>
        </>
    );
};

export default ErrorFallback;
