import { createSlice } from "@reduxjs/toolkit";

/**
 * Initial state
 */
const initialState = {
    error: null
};

/**
 * Slice
 */
export const errorSlice = createSlice({
    name: "error",
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
        resetError: (state) => {
            state.error = null;
        }
    }
});

/**
 * Actions
 */
export const {
    setError,
    resetError
} = errorSlice.actions;

/**
 * default export reducer
 */
export default errorSlice.reducer;

/**
 * selectors
 */
export const selectError = state => state.error.error;

