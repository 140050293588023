import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import styled, { css } from "styled-components";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { COLORS } from "../constants/colors";

const StyledMenu = styled.ul`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
list-style-type: none;
color: ${COLORS.primarys[8]};
font-size: 2rem;
`;

const Menu = ({ children, ...props }) => {
    return <StyledMenu {...props}>{children}</StyledMenu>;
};

// Header Row
const StyledHeaderRowContainer = styled.div`
    display: flex;
    padding: 1rem 1rem;
    align-items: center;
    cursor: pointer;
`;
const HeaderRow = ({ title, isOpen, ...props }) => {
    return (
        <StyledHeaderRowContainer {...props}>
            <span>{title}</span>
            {isOpen ? (
                <FaChevronUp style={{ marginLeft: "auto" }} />
            ) : (
                <FaChevronDown style={{ marginLeft: "auto" }} />
            )}
        </StyledHeaderRowContainer>
    );
};

// Sub Menu Ul
const StyledSubMenuUl = styled.ul`
    background-color: #fafafa;
    list-style: none;
    overflow: hidden;
    max-height: ${(props) => (props.isOpen ? "10000px" : "0px")};
    transition: all 0.2s;

    > li {
        padding-left: 4rem;
        border-top: 1px solid #eee;
    }
`;
// Sub Menu
const StyledSubMenu = styled.li``;
const SubMenu = ({ title, closeOnRouteChange = true, children, ...props }) => {
    const [isOpen, setOpen] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (closeOnRouteChange) {
            setOpen(false);
        }
    }, [setOpen, closeOnRouteChange, location, location.key]);

    return (
        <StyledSubMenu {...props}>
            {/* Header */}
            <HeaderRow
                title={title}
                isOpen={isOpen}
                onClick={() => {
                    setOpen(!isOpen);
                }}
            />
            <StyledSubMenuUl isOpen={isOpen}>{children}</StyledSubMenuUl>
        </StyledSubMenu>
    );
};

const listItemCommonCss = css`
    display: block;
    padding: 1.5rem 1rem;
`;

const listItemCss = css``;

const ListItem = styled.li`
    ${listItemCommonCss}
    ${listItemCss}
`;

const ListItemWithComponentInside = styled.li`
    ${listItemCss}

    > * {
        ${listItemCommonCss}
    }
`;

const Item = ({ children, component, ...props }) => {
    const Component = component;

    if (Component) {
        return (
            <ListItemWithComponentInside>
                <Component {...props}>{children}</Component>
            </ListItemWithComponentInside>
        );
    }

    return <ListItem {...props}>{children}</ListItem>;
};

Menu.SubMenu = SubMenu;
Menu.Item = Item;
export default Menu;
