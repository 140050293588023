import styled from "styled-components";
import H3 from "./typography/H3";
import { COLORS } from "../constants/colors";

const Heading3 = styled(H3)`
    padding: 1rem .7rem;
    border-left: 6px solid ${COLORS.greyBlues[5]};
    background: #ddd;
    margin: 1rem auto 0;
    max-width: 1200px;
    width: 100%;
`;

export default Heading3;