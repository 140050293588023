import styled from "styled-components";
import H2 from "./typography/H2";
import { COLORS } from "../constants/colors";

const Heading2 = styled(H2)`
    position: relative;
    padding: 1rem;
    /* background-color: ${COLORS.BACKGROUND_COLOR}; */
    border-bottom: 6px solid ${COLORS.greyBlues[5]};
    max-width: 1200px;
    margin: 2rem auto 0;
    /* z-index: -1; */

    :before {
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 30%;
        height: 6px;
        content: "";
        background-color: ${COLORS.greyBlues[3]};
    }
`;


export default Heading2;
