import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (history.action === "PUSH" || history.action === "REPLACE") {
            window.scrollTo(0, 0);
        }
    }, [history.action, pathname]);

    return null;
}
