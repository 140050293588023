import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const CustomModal = ({ showModal, title, actions, footerPropsArray, handleClose, manager, children }) => {
	let _actions = [{
        text: "關閉",
        action: handleClose,
    }]

    if (actions && actions.length > 0) {
        _actions = [..._actions, ...actions];
    }
    
    return (
		<Modal restoreFocus={false} onHide={handleClose} show={showModal} size="lg" aria-labelledby="contained-modal-title-vcenter" manager={manager} centered>
			<Modal.Header>
				<Modal.Title style={{ fontSize: "2rem" }}>{title}</Modal.Title>
			</Modal.Header>
			{children}
			<Modal.Footer>
                { _actions.map(({ text, action, actionButtonBackgroundColor }, index) => {
                    return (
                        <Button style={{ fontSize: "1.6rem", backgroundColor: actionButtonBackgroundColor }} variant="secondary" onClick={action} key={index}>
							{text}
						</Button>
                    )
                }) }
			</Modal.Footer>
		</Modal>
	);
};

export default CustomModal;
