import { useSelector, useDispatch } from "react-redux";

import {
    selectNotifications,
    selectNotificationPage,
    selectNotificationsTotalPageCount,
    getNotifications,
    addOne,
    upsertMany,
    getUnReadCount,
    selectNotificationsUnReadCount,
    updateUnReadCount,
    addOneUnReadCount,
    resetNotification,
} from "../features/notification/notificationSlice";

import filter from "lodash/filter";
import some from "lodash/some";

import useAuth from "./useAuth";
import { useRef } from "react";

const useNotification = () => {
    const dispatch = useDispatch();
    const notificationsPage = useSelector(selectNotificationPage);
    const notificationsTotalPageCount = useSelector(
        selectNotificationsTotalPageCount
    );

    // console.log({ notificationsPage, notificationsTotalPageCount });

    const user = useAuth();

    const fetchNotifications = useRef(() => {});
    // const fetchFiniteNotifications = useRef(() => {});
    const addOneNotification = useRef(() => {});
    const upsertManyNotification = useRef(() => {});
    const hasMoreNotifications = useRef(() => {});
    const getUnReadCountRef = useRef(() => {});
    const updateUnReadCountRef = useRef(() => {});
    const addOneUnReadCountRef = useRef(() => {});
    const resetNotificationRef = useRef(() => {});

    fetchNotifications.current = (id) => {
        return dispatch(getNotifications({ page: notificationsPage + 1 }));
    };

    addOneNotification.current = (notification) => {
        return dispatch(addOne(notification));
    };

    upsertManyNotification.current = (updatedNotifications) => {
        return dispatch(upsertMany(updatedNotifications));
    };

    hasMoreNotifications.current = () => {
        return notificationsPage < notificationsTotalPageCount;
    };

    getUnReadCountRef.current = () => {
        return dispatch(getUnReadCount());
    };

    updateUnReadCountRef.current = (unReadCount) => {
        return dispatch(updateUnReadCount(unReadCount));
    };

    addOneUnReadCountRef.current = () => {
        return dispatch(addOneUnReadCount());
    };

    resetNotificationRef.current = () => {
        return dispatch(resetNotification());
    };

    const unReadCount = useSelector(selectNotificationsUnReadCount);

    const notifications = useSelector(selectNotifications);

    const unReadNotifications = user._id
        ? filter(notifications, (obj) => {
              return !some(obj.read_by, { readerId: user._id });
          })
        : [];

    return {
        notifications,
        unReadNotifications,
        unReadCount,
        fetchNotifications,
        addOneNotification,
        upsertManyNotification,
        hasMoreNotifications,
        // fetchFiniteNotifications,
        getUnReadCountRef,
        updateUnReadCountRef,
        addOneUnReadCountRef,
        resetNotificationRef,
    };
};

export default useNotification;
