import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as wfsAuthAPI from "../../api/wfsAPI/auth";

/**
 *  Initial state
 */
const initialState = {
    status: "idle",
    user: {
        _id: null,
        username: null,
        nickname: null,
        role: null,

        status: null,       // !!! Please do strict checking in backend, this "status" is only for display
    },
    accessToken: null,
    expiresIn: null,
};

// extract later
const errorResponse = (err) => {
    return {
        status: err.response.status,
        data: err.response.data,
        headers: err.response.headers,
    };
};

// { username: "...", password: "...", role: "...", nickname: "..."  }
export const signup = createAsyncThunk(
    "user/signup",
    async (data, { rejectWithValue }) => {
        try {
            const response = await wfsAuthAPI.signup(data);

            return response.data;
        } catch (err) {
            return rejectWithValue(errorResponse(err));
        }
    }
);

// { username: "...", password: "..." }
export const login = createAsyncThunk(
    "user/login",
    async (data, { rejectWithValue }) => {
        try {
            const response = await wfsAuthAPI.login(data);

            // user info
            return response.data;
        } catch (err) {
            return rejectWithValue(errorResponse(err));
        }
    }
);

export const getNewAccessToken = createAsyncThunk(
    "user/getNewAccessToken",
    async (data, { rejectWithValue }) => {
        try {
            const response = await wfsAuthAPI.getNewAccessToken();

            // access token
            return response.data;
        } catch (err) {
            return rejectWithValue(errorResponse(err));
        }
    }
);

export const logout = createAsyncThunk(
    "user/logout",
    async (data, { rejectWithValue }) => {
        try {
            await wfsAuthAPI.logout();
        } catch (err) {
            return rejectWithValue(errorResponse(err));
        }
    }
);

// reducers
const loading = (state) => {
    state.status = "loading";
};

const authSuccess = (state, action) => {
    const { user, accessToken, expiresIn } = action.payload;

    state.user = user;
    state.accessToken = accessToken;
    state.expiresIn = expiresIn;

    state.status = "idle";
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        authSucceeded: authSuccess
    },
    extraReducers: (builder) => {
        builder
            .addCase(signup.pending, loading)
            .addCase(signup.fulfilled, authSuccess)
            .addCase(login.pending, loading)
            .addCase(login.fulfilled, authSuccess)
            .addCase(getNewAccessToken.pending, loading)
            .addCase(getNewAccessToken.fulfilled, authSuccess)
            .addCase(logout.pending, loading)
            .addCase(logout.fulfilled, () => initialState);
    },
});

/**
 *  Actions
 */
export const { authSucceeded } = userSlice.actions;

/**
 * default export reducer
 */
export default userSlice.reducer;

/**
 * selectors
 */
export const selectAuthStatus = (state) => state.user.status;
export const selectUser = (state) => state.user.user;
export const selectAccessToken = (state) => state.user.accessToken;
export const selectRefreshToken = (state) => state.user.refreshToken;
