import React from "react";
import styled from "styled-components";
import Heading2 from "../components/Heading2";
import Heading3 from "../components/Heading3";
import { COLORS } from "../constants/colors";

const Container = styled.div`
    background-color: ${COLORS.BACKGROUND_COLOR};
    font-size: 1.4rem;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 1rem 2rem;
`;

const Ul = styled.ul`
    margin: 0.5rem 0 2rem;
    padding-left: 2rem;

    > ul {
        padding-left: 2rem;
    }
`;

const Li = styled.li`
    margin: 0 0 0.5rem;
`;

const P = styled.p`
    margin: 0 0 1rem;
`;

const Privacy = () => {
    return (
        <Container>
            <Heading2>私隱政策</Heading2>

            <Heading3>私隱聲明</Heading3>
            <P>
                本私隱聲明概述我們如何收集、使用、管理和保護我們可能向閣下收集或所收集到有關閣下的個人資料（「個人資料」），並適用於其個人資料可能由一門三傑科技公司*（「The
                Company」或「我們」）作為控制者或處理者經手的所有個人，包括但不限於WaterFun
                Square水樂坊服務的會員。
            </P>

            <Heading3>保障閣下的私隱</Heading3>
            <P>
                我們竭力按照規定的標準處理閣下的資料，其中包括保障閣下的私隱及確保閣下個人資料的安全，以遵守（尤其是及在適用情況下）香港法例第486章《個人資料（私隱）條例》（「條例」），以及歐洲議會和歐盟理事會在2016年4月27日通過有關個人資料處理及自由流動的個人保障的第2016/679號歐盟規則（「GDPR」，個人資料保護規則）（如適用）。
            </P>
            <P>
                為本私隱聲明列明之目的使用或提供閣下的個人資料前，我們可能會因法例規定須要索取閣下的書面同意。在此情況下，我們只會在取得閣下的同意後，才會開始按指定的方式使用閣下的個人資料。
            </P>

            <Heading3>閣下的個人資料</Heading3>
            <P>
                我們或會收集、使用及保留閣下各種個人資料。為了經營我們的業務（包括核實閣下的身份以偵查、預防及處理欺詐、保安或技術問題、登記、啟動及管理閣下在水樂坊的帳戶，以及我們的水上活動和平台服務的帳單和收費（統稱「服務」））及遵從任何適用之政府相關部門、法院、執法或有關當局或監管機構所發佈的法律、規定、指引、規則及／或要求，閣下可能需要提供包括但不限於下列的個人資料：
            </P>
            <Ul>
                <Li>
                    姓名、出生日期及閣下的身份證或其他法定證明文件、旅遊證明文件、學生證及／或駕駛執照所記載的其他詳情；
                </Li>
                <Li>
                    聯絡資料，包括閣下的姓名、地址、電話號碼、手提電話號碼及／或電郵地址；
                </Li>
                <Li>
                    閣下與第三方社交媒體平台經營商分享的個人資料（例如帳戶登入姓名、圖像、聯絡資料）；
                </Li>
                <Li>付款資料，包括信用卡、扣帳卡和其他網上銀行資料；</Li>
                <Li>
                    與登記使用我們的服務相關的帳戶詳情或個人資料，包括相關的PIN、用戶名稱或密碼、帳戶號碼及／或服務號碼；
                </Li>
                <Li>
                    裝置的具體資料，例如硬件模型、作業系統、版本、唯一識別碼、序號、設定配置以及軟件和流動網絡配置；
                </Li>
                <Li>
                    有關閣下如何使用我們的服務的資料，例如閣下的網絡使用情況、閣下如何使用我們的網絡，以及閣下使用我們的服務時的位置；
                </Li>
                <Li>
                    使我們能識別以核實閣下身份的資料，包括生物識別資料，例如閣下的指紋和聲紋；
                </Li>
                <Li>
                    閣下的信用及服務記錄，使我們能評估閣下是否合資格使用我們的服務或處理閣下轉移服務或帳戶的要求；
                </Li>
                <Li>
                    適用的政府相關部門、法院、執法或有關當局或監管機構所要求使我們能遵守任何法律、規定、規則、判決或法庭命令（不論是在香港境內或境外）或與此有關的所有個人資料；及
                </Li>
                <Li>
                    我們任何成員及／或其各自的承辦商、分包商、中介、代理商、商業夥伴或代表、經紀或承包商可能不時要求就提供服務而言為必需的任何其他個人資料。
                </Li>
            </Ul>
            <P>
                在某些情況下，如法律有所規定，我們可能會徵求閣下同意我們處理下列類別的「特別個人資料」，使我們能進一步改善服務及／或為閣下提供更適切的類別的資料或內容：
            </P>
            <Ul>
                <Li>年齡；</Li>
                <Li>性別及種族；</Li>
                <Li>婚姻狀況；</Li>
                <Li>薪金水平及就業資料；</Li>
                <Li>教育程度及專業資格；</Li>
                <Li>嗜好及休閒活動；</Li>
                <Li>閣下已申請的活動；及</Li>
                <Li>家庭及家居統計資料。</Li>
            </Ul>
            <P>
                閣下可選擇是否提供上述的特別個人資料。但當所需服務是個人化服務，或產品的提供是建基於閣下已提供一切所需個人資料，若閣下屆時未能提供所需的個人資料，可能會有礙我們向閣下提供該等特定服務。
            </P>
            <P>
                閣下或須向我們提供其他人士的個人資料（例如同行的參與者）。在此情況下，我們或會要求閣下確認閣下會遵從私隱條例，並確認閣下已告知該等人士：個人資料的使用、披露及轉移（包括由閣下向我們提供的個人資料以及由我們向第三者提供的個人資料），以及我們可能向閣下披露該等人士的詳情（包括該等人士使用我們的服務、會員的詳情）；而閣下已獲得該等人士就該等使用、披露及轉移的授權及／或同意（或者有關授權及／或同意的適用範圍）。閣下亦應提醒該等人士可以聯絡我們以索取進一步資料（請參閱下述之「聯絡方式」）。
            </P>
            <P>
                經閣下提供的個人資料，將會由我們的一名或以上成員保留，並在符合有關情況或為本私隱聲明列明之目的或事先通知閣下的其他目的或（如有需要）在取得閣下的同意後，供該等成員的僱員及獲授權的第三方使用。
            </P>

            <Heading3>我們如何收集個人資料</Heading3>
            <P>我們會通過不同的方式收集個人資料，包括：</P>
            <P>
                直接向閣下收集。例如閣下透過電話或電郵、出席我們的活動、填寫我們某項服務的申請表格或協議，或通過我們的網站、流動或電視應用程式或任何客戶服務熱線或網上聊天室提交閣下的個人資料，或當閣下聯絡我們提出查詢或要求時，或在日常商業事務中與我們保持聯繫期間，或當我們根據法規要求收集該等個人資料；
            </P>
            <Ul>
                <Li>
                    經閣下同意後向第三方，例如相關的公司、業務合作夥伴、或其他客戶或閣下的代表收集；
                </Li>
                <Li>從公開資料來源收集；</Li>
                <Li>根據我們內部有關閣下如何使用我們的服務的記錄中收集；</Li>
                <Li>
                    當閣下瀏覽我們的網站、流動應用程式（請參閱下述「私隱資料」）；及／或
                </Li>
                <Li>當閣下參與由我們或我們的代表舉行的調查或促銷活動。</Li>
            </Ul>
            <Heading3>私隱資料</Heading3>
            <P>
                為了更能滿足閣下的需要和喜好，我們的網頁伺服器會收集與閣下的網站、裝置或應用程式活動有關的個人資料。我們亦會收集伺服器使用量的不記名集體統計資料，使我們能更適當地回應我們網站及流動和電視應用程式用戶的行為模式。這類個人資料可包括但不限於：
            </P>
            <Ul>
                <Li>瀏覽器類型、版本及用戶代理；</Li>
                <Li>操作系統；</Li>
                <Li>IP（互聯網通訊協定）地址及／或網域名稱；</Li>
                <Li>連接數據、網頁瀏覽及／或推薦連結的統計數據及／或；</Li>
                <Li>裝置識別資料、位置及電話聯絡人；</Li>
                <Li>已觀看或搜尋的影片；</Li>
                <Li>已點擊的連結或圖像；</Li>
                <Li>
                    「曲奇」（cookies）及／或瀏覽器、應用程式或網頁伺服器的日誌記錄；及
                </Li>
                <Li>裝置及軟件特性及／或配置。</Li>
            </Ul>
            <P>
                我們的一些網站會在閣下的電腦或裝置內使用「曲奇」（cookies）或類似的追蹤工具，以便我們（例如）為閣下提供個人化的服務及／或於閣下瀏覽不同網頁及／或互聯網期間進行身份識別。此等個人資料可包括但不限於登入及確認資料，以及有關閣下的裝置及閣下在我們網站及流動和電視應用程式內的活動和喜好設定的個人資料。我們的網站在最初會設定為接受「曲奇」。閣下可以透過更改閣下網頁瀏覽器的設定選擇拒絕或刪除「曲奇」的歷史記錄；但是，閣下在更改有關設定後，可能無法如常使用我們網站及／或應用程式的某些功能。
            </P>

            <Heading3>我們如何使用閣下的個人資料</Heading3>
            <P>我們可能收集、保留及使用閣下的個人資料以作如下用途：</P>
            <Ul>
                <Li>核實閣下的身份；</Li>
                <Li>處理閣下選用我們的活動計劃的申請；</Li>
                <Li>根據條例進行核對程序；</Li>
                <Li>
                    核實閣下選用或參加我們的服務及／或推廣或其他活動的資格；
                </Li>
                <Li>提供或啟動閣下已申請的服務；</Li>
                <Li>為閣下提供獎賞、推廣優惠、更新、優惠及活動邀請；</Li>
                <Li>向閣下宣傳及促銷我們的服務；</Li>
                <Li>
                    遵照香港法例第615章《打擊洗錢及恐怖分子資金籌集條例》進行信用審查及偵查欺詐活動，以及其他合規目的；
                </Li>
                <Li>
                    進行研究或分析，以改進和完善我們為閣下所提供的服務計劃；
                </Li>
                <Li>
                    為業務營運及／或商業規劃進行調查及促銷、宣傳及行為評分；
                </Li>
                <Li>
                    進行市場和產品分析作統計或精算報告（包含與任何已識別或可識別的個人無關的整體數據）；
                </Li>
                <Li>執行我們的合約權利；</Li>
                <Li>
                    就我們向閣下提供服務處理付款指示、直接扣帳安排及／或賒帳安排；
                </Li>
                <Li>
                    維持和開發我們的業務系統和基礎設施，包括測試及更新該等系統；
                </Li>
                <Li>維持、提升和發展我們的活動及服務；</Li>
                <Li>開發水上活動預約服務及產品；</Li>
                <Li>
                    評估閣下的財務需要以及由或針對閣下提出的保險索償，以及處理與閣下有關的索償（包括但不限於提出、辯護、分析、調查、處理、評估、釐定、回應或解決該等索償或進行和解）；
                </Li>
                <Li>
                    就我們與主辦單位的互連安排遵守我們的牌照責任以及相關的行業守則；
                </Li>
                <Li>
                    就我們向閣下提供服務計劃，遵守適用的政府相關部門、法院、執法或監管或調查當局所規定的香港境內或海外適用法律，包括協助預防或偵查犯罪或潛在犯罪活動；及
                </Li>
                <Li>
                    發佈我們的刊物及研究資料，以及我們的商業合作夥伴及對手方的刊物及研究資料。
                </Li>
            </Ul>
            <Heading3>
                我們使用閣下的個人資料（包括我們如何披露閣下的個人資料）的法律依據
            </Heading3>
            <P>
                妥善地執行服務關乎我們的合法利益。此外，為履行閣下所要求的服務，我們可能有必要使用閣下的個人資料。在適用法律和規例准許的範圍內，我們或會將您的個人資料披露給我們以外的機構或人士（可能位於香港境內或海外）（統稱「機構」），從而提供閣下所要求的服務。向該等機構披露閣下個人資料之目的，只會嚴格限制於使我們能向閣下提供我們的服務。
                在上述的某些情況中，我們或需根據法律規定徵求閣下同意我們使用閣下的特別個人資料。
            </P>

            <Heading3>
                下列機構為我們的業務和運作提供支持服務，包括但不限於：
            </Heading3>
            <Ul>
                <Li>客戶查詢；</Li>
                <Li>快遞、送貨、物流及倉庫服務；</Li>
                <Li>郵件操作；</Li>
                <Li>帳單和欠款收取；</Li>
                <Li>資訊科技服務；</Li>
                <Li>安裝、保養及維修服務；</Li>
                <Li>市場促銷、廣告及電話促銷服務；</Li>
                <Li>市場調查；</Li>
                <Li>客戶使用量及行為分析；</Li>
                <Li>程序管理；</Li>
                <Li>售後服務；</Li>
                <Li>調查；</Li>
                <Li>網站使用量分析；及</Li>
                <Li>雲端儲存服務。</Li>
            </Ul>
            <P>
                我們會採取必要的措施，以確保該等機構是受有關保障閣下個人資料的保密及私隱責任的約束，且該等機構僅就他們受聘執行的服務之目的，而不能為自用或其他目的（包括直接促銷）使用閣下的個人資料。
            </P>

            <Heading3>此外，我們可能會向下列各方披露閣下的個人資料：</Heading3>
            <Ul>
                <Li>閣下的授權代表及╱或法律顧問（在閣下要求下）；</Li>
                <Li>下述為提供行政、付款、收款、商業、法律及</Li>
                /或業務支持的機構：
                <Li>信貸報告和欺詐調查機構；</Li>
                <Li>
                    金融機構、付款卡或信用卡發卡公司、信貸機構、信貸資料公司、債務代收或證券代理；
                </Li>
                <Li>電訊網絡營運商；</Li>
                <Li>我們的附屬成員、海外辦事處、委托人、受讓方及代表；</Li>
                <Li>
                    我們的專業顧問，包括我們的會計師、核數師、律師及保險人；
                </Li>
                <Li>
                    銀行、酒店、保險公司、保險經紀、承保人、帳單代理及其他商業夥伴，包括但不限於協助會員登記參與有關的活動；
                </Li>
                <Li>政府及監管機構及其他法律規定或授權的機構；</Li>
                <Li>
                    管理我們業務及企業策略的機構，包括為該等目的參與轉讓或出售我們全部或部份的資產或業務（包括帳戶和應收帳款）以及參與管理我們的企業風險和資金籌集功能（例如證券化）的機構；
                </Li>
                <Li>
                    有關我們的全部或部份運營業務的任何潛在或實際參與者、委托人或受讓方；及╱或
                </Li>
                <Li>慈善或非牟利機構。</Li>
            </Ul>

            <Heading3>直接促銷 (如適用)</Heading3>
            <Ul>
                <Li>
                    我們在遵守適用法律、規則、規例和牌照規定的情況下，或會使用閣下的資料（包括但不限於姓名、聯絡資料、電話號碼、住宅／辦公室地址、電郵地址及閣下已選購的產品的資料），以便我們（包括Water
                </Li>{" "}
                Fun
                Square　水樂坊）及我們各自的經銷商、供應商、授權人、提供商及／或業務合作夥伴（統稱「服務供應商」）為閣下提供直接促銷資料（我們可能會、也可能不會就此獲得報酬），包括向閣下發出與我們及／或服務供應商提供的服務有關的禮品、折扣、尊享優惠、優惠和推廣的通知及／或最新情報，包括但不限於：電視、電訊、OTT服務、資料服務、流動話音、短訊及數據通訊、IDD／國際漫遊、互聯網連接、雲端服務、手機付款、娛樂、秘書服務、個人助理服務和資訊服務（例如天氣、財經和新聞資訊）、裝置配件、流動應用程式和軟件、電腦周邊產品、配件和軟件（包括筆記型電腦、手提電話、流動裝置及配件、鍵盤、防禦裝置及流動應用程式）、獎賞、忠誠或尊貴計劃、生活方式、社交活動、旅遊、銀行、酒精及煙草、運動、音樂、遊戲、交通、家居產品、餐飲、金融、保險、財富管理服務和產品、退休金、投資、經紀服務、理財顧問、信貸及其他理財服務和產品、博彩、教育、健康及保健、美容產品和服務、時尚及配飾、電子產品、社交網絡、科技、電子商務、物流、零售、家居佈置、媒體及高端消費類產品及服務。該等促銷可透過多種方式進行（包括以郵件、於帳單內夾寄／簡訊、電郵、數字短訊、多媒體短訊、即時消息、應用程式推送通知、針對性的電視消息、透過電話或社交媒體或網站顯示廣告或其他方式）。
                <Li>
                    在將你的個人資料用作本私穩聲明所述的目的之前，我們可能會因法例規定須要索取閣下的書面同意。在此情況下，我們只會在取得閣下書面同意後，才開始使用及提供你的個人資料作任何推廣或市場用途。
                </Li>
                <Li>
                    我們尊重所有停止使用個人資料作直接促銷的要求，你可選擇拒絕接收直接促銷資料及╱或有關活動計劃及╱或The
                    Company的通訊。與此同時，若你有意重新收取該等資料（重收促銷資料要求只適用於以往曾經拒絕接收該等資料及╱或有關服務及╱或The
                    Company的通訊的客戶)，你可連同註冊名字、帳戶號碼、註冊電話號碼或登入帳戶名稱（如適用）等郵寄通知我們，告知我們你願意收取這些資料。
                </Li>
            </Ul>

            <Heading3>轉移個人資料至香港以外地方</Heading3>
            <P>
                本公司在有需要時會審慎地將你的個人資料轉移至香港特別行政區以外的地方以達到不同目的，例如預防、偵查或調查犯罪行為、或以達到儲存、處理此等資料及其他收集資料之目的。如本公司將你的個人資料轉移至香港特別行政區以外的地方，該轉移會遵守條例及GDPR（如適用）的現行之規定。
            </P>

            <Heading3>我們重視閣下個人資料的安全性</Heading3>
            <P>
                我們盡力確保所有我們客戶的個人資料均儲存於可靠安全的地方。該等個人資料只有經我們授權的僱員或在本私穩聲明中提及的其他機構方可查閱。
            </P>
            <P>
                若我們將你的個人資料轉交予第三方機構處理，我們會盡量確保該等機構有適當的安全措施來保護你的個人資料的安全性並遵守有關個人資料保護的適用原則。收到你的個人資料的一方可能會在海外處理這些個人資料。若閣下欲瞭解本公司爲了確保你的個人資料得到充分保護而使用的安全措施，閣下可以與本公司聯絡，索取更多資料。
            </P>

            <Heading3>個人資料的保存</Heading3>
            <P>
                本公司將會根據內部政策，持有你的個人資料。本公司的政策符合條例及GDPR（如適用），及包括以下宗旨：
            </P>
            <P>
                個人資料會保存至達到與收集上述資料之原來目的，或直接有關之目的為止；除非上述個人資料可根據任何適用法律、法規或合約規定須予以保存；
            </P>
            <P>
                基於上述標準及本公司的內部程序，個人資料會在本公司的電子、人手及其他儲存系統中被清除。
            </P>

            <Heading3>鏈結</Heading3>
            <P>
                我們網站(包括但不限於www.waterfunsquare.com）及流動應用程式（如有）或包含可通往其他地址、網頁及由第三者營運的流動應用程式的鏈接。我們無法控制該等被鏈接網站、網頁及流動應用程式的內容或該等網站、網頁及流動應用程式營運商處理閣下個人資料的方式，並概不對該等第三方網站、網頁或流動應用程式的内容負責。閣下應審閱該第三者網站、網頁及流動應用程式上的私隱政策，以瞭解該等第三者可能使用閣下個人資料的方式。
            </P>

            <Heading3>閣下查閲、更正及刪除個人資料的權利</Heading3>
            <P>
                我們採取一切合理的預防措施，以確保我們收集、使用及披露的個人資料是準確、完整和最新的。然而，個人資料的準確性在很大程度上取決於你提供的個人資料。你有權要求查閱和更正你的個人資料，我們建議你：
            </P>
            <Ul>
                <Li>當閣下發現個人資料記錄有任何錯誤時請通知我們；及</Li>
                <Li>請通知我們若閣下的個人資料有所改變或更新。</Li>
            </Ul>
            <P>
                若閣下希望查閲或更正我們持有的閣下的任何個人資料，或要求我們刪除(僅在有相關法律的要求下適用)任何不再因爲我們提供的服務而需要保存的資料，你可以按照下述之「聯絡方式」與我們聯絡。
            </P>
            <P>
                如閣下為水樂坊之會員，你可登入水樂坊會員賬戶以查閲和更正你的個人資料，行使閣下的查閱及修正權利。如閣下不是水樂坊會員，你可以按照下述之「聯絡方式」致函本公司的私隱條例事務主任。
            </P>
            <P>我們可因應閣下的要求所查閱的資料收取行政費。</P>
            <P>
                閣下可以拒絕與我們分享你的個人資料及╱或撤回你作出的任何同意，在這種情況下，我們可能無法向你提供我們的某些服務。
            </P>
            <P>
                除非適用的法律允許本公司持有或處理你的個人資料，否則，閣下可以隨時以合法的理由反對我們持有或處理你的個人資料。
            </P>

            <Heading3>聯絡方式</Heading3>
            <P>
                任何有關我們是否遵從條例及GDPR（如適用）的責任查詢及任何查閱、更改或刪除個人資料的請求，請以書面形式致函以下地址：
            </P>
            <div
                style={{
                    paddingLeft: "2rem",
                }}
            >
                <P
                    style={{
                        marginBottom: "0rem",
                    }}
                >
                    一門三傑科技公司
                </P>
                <P>PO Box 308, Ma On Shan Post Office, NT, Hong Kong.</P>
            </div>
            <P>
                或電郵至：
                <a href="mailto:waterfunsquare@gmail.com">waterfunsquare@gmail.com</a>
            </P>
            <P>
                如對有關個人資料之處理有任何疑問，請與我們聯絡，我們會盡力解決閣下的疑問。
            </P>
            <P>
                本私隱聲明或會經不時修訂，所有個人資料之處理將受制於本私隱聲明的最新版本（詳見{" "}
                <a href="https://www.waterfunsquare.com/privacy">
                    www.waterfunsquare.com/privacy
                </a>
                ）。若中、英文版本有歧義之處，概以中文版本為準。
            </P>
            <div
                style={{ marginTop: "4rem" }}
            >
                <P>
                    <small>* 一門三傑科技公司是一家於香港註冊成立的公司</small>
                </P>
            </div>
        </Container>
    );
};

export default Privacy;
