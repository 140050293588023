import React, { useState, useEffect, useContext, lazy, Suspense } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import {
    useSelector,
    useDispatch,
    Provider as ReduxProvider,
} from "react-redux";
import styled from "styled-components";
import { ConnectedRouter } from "connected-react-router";
import { QueryClient, QueryClientProvider } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import store from "./app/store";
import history from "./app/history";
import { resetError, selectError } from "./features/error/errorSlice";
import { COLORS } from "./constants/colors";
import SideMenu from "./components/includes/SideMenu";
import Footer from "./components/includes/Footer";
import Navbar from "./components/includes/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import CustomModal from "./components/Modal";
import { ROUTE_PATH } from "./constants/routePaths";
import PrivateRoute from "./components/routes/PrivateRoute";
import PublicRoute from "./components/routes/PublicRoute";
import { ToastPortal } from "./components/ToastNotifications/ToastPortal/ToastPortal";
import { ModalProvider } from "./contexts/ModalContext";
import SocketContext from "./contexts/SocketContext";
import { SocketProvider } from "./contexts/SocketContext";
import { ToastProvider } from "./contexts/ToastContext";
import useModal from "./hooks/useModal";
import useNotification from "./hooks/useNotification";
import useAuth from "./hooks/useAuth";
import useScrollPosition from "./hooks/useScrollPosition";
import useToast from "./hooks/useToast";
import PageLoading from "./components/PageLoading";
import ErrorFallback from "./pages/ErrorFallback";
import Privacy from "./pages/Privacy";
import componentLoader from "./components/componentLoader";
import ShipsList from "./pages/ShipsList";

// Lazy Loading
const Home = lazy(() => componentLoader(() => import("./pages/Home")));
const Login = lazy(() => componentLoader(() => import("./pages/Login")));
const SignUp = lazy(() => componentLoader(() => import("./pages/SignUp")));
const OrganizerInfo = lazy(() =>
    componentLoader(() => import("./pages/OrganizerInfo"))
);
const StoreInfo = lazy(() =>
    componentLoader(() => import("./pages/StoreInfo"))
);
const ProvideOrganizerInfo = lazy(() =>
    componentLoader(() => import("./pages/ProvideOrganizerInfo"))
);
const ProvideStoreInfo = lazy(() =>
    componentLoader(() => import("./pages/ProvideStoreInfo"))
);
const TermsAndConditions = lazy(() =>
    componentLoader(() => import("./pages/TermsAndConditions"))
);
const TermsOfUse = lazy(() =>
    componentLoader(() => import("./pages/TermsOfUse"))
);
const EventInfo = lazy(() =>
    componentLoader(() => import("./pages/EventInfo"))
);
const OrganizerLocationManagement = lazy(() =>
    componentLoader(() => import("./pages/OrganizerLocationManagement"))
);
const ShipManagementForm = lazy(() =>
    componentLoader(() => import("./pages/ShipManagementForm"))
);
const CreateEventInfoForm = lazy(() =>
    componentLoader(() => import("./pages/CreateEventInfoForm"))
);
const EventManagement = lazy(() =>
    componentLoader(() => import("./pages/EventManagement"))
);
const FlowOperation = lazy(() =>
    componentLoader(() => import("./pages/FlowOperation"))
);
const FlowReservation = lazy(() =>
    componentLoader(() => import("./pages/FlowReservation"))
);
const FlowCancel = lazy(() =>
    componentLoader(() => import("./pages/FlowCancel"))
);
const AboutUs = lazy(() => componentLoader(() => import("./pages/AboutUs")));
const ContactUs = lazy(() =>
    componentLoader(() => import("./pages/ContactUs"))
);
const ShipInfo = lazy(() => componentLoader(() => import("./pages/ShipInfo")));
const NotFound = lazy(() => componentLoader(() => import("./pages/NotFound")));
const ParticipantReservationForm = lazy(() =>
    componentLoader(() => import("./pages/ParticipantReservationForm"))
);
const ParticipantCancelReservationForm = lazy(() =>
    componentLoader(() => import("./pages/ParticipantCancelReservationForm"))
);
const EditProfile = lazy(() =>
    componentLoader(() => import("./pages/EditProfile"))
);
const FishingLocationProfileInfo = lazy(() =>
    componentLoader(() => import("./pages/FishingLocationProfileInfo"))
);
const Notification = lazy(() =>
    componentLoader(() => import("./pages/Notification"))
);
const BenefitsMember = lazy(() =>
    componentLoader(() => import("./pages/BenefitsMember"))
);
const BenefitsOrganizer = lazy(() =>
    componentLoader(() => import("./pages/BenefitsOrganizer"))
);
const Profile = lazy(() => componentLoader(() => import("./pages/Profile")));
const FishingAppHome = lazy(() =>
    componentLoader(() => import("./pages/FishingAppHome"))
);
const ReservationsList = lazy(() =>
    componentLoader(() => import("./pages/ReservationsList"))
);
const EventsList = lazy(() =>
    componentLoader(() => import("./pages/EventsList"))
);
const SearchEventInfo = lazy(() =>
    componentLoader(() => import("./pages/SearchEventInfo"))
);
const EmailConfirmation = lazy(() =>
    componentLoader(() => import("./pages/EmailConfimation"))
);
const CheckEmailAcknowledgement = lazy(() =>
    componentLoader(() => import("./pages/CheckEmailAcknowledgement"))
);
const Checkout = lazy(() => componentLoader(() => import("./pages/Checkout")));
const StripeAccountLink = lazy(() =>
    componentLoader(() => import("./pages/StripeAccountLink"))
);
const SuccessPayment = lazy(() =>
    componentLoader(() => import("./pages/SuccessPayment"))
);
const PasswordResetForm = lazy(() =>
    componentLoader(() => import("./pages/PasswordResetForm"))
);
const PasswordResetReenterPassword = lazy(() =>
    componentLoader(() => import("./pages/PasswordResetReenterPassword"))
);
const SearchUser = lazy(() =>
    componentLoader(() => import("./pages/SearchUser"))
);
const CreateStripeAccount = lazy(() =>
    componentLoader(() => import("./pages/CreateStripeAccount"))
);
const OrganizerInvoice = lazy(() =>
    componentLoader(() => import("./pages/OrganizerInvoice"))
);
const ImportantDates = lazy(() =>
    componentLoader(() => import("./pages/ImportantDates"))
);
const CheckOrganizerInvoice = lazy(() =>
    componentLoader(() => import("./pages/CheckOrganizerInvoice"))
);

const queryClient = new QueryClient();

// import * as wfsUserProfileAPI from "./api/wfsAPI/user-profile";

const Overlay = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 500;
    transition: opacity 0.3s;
    opacity: ${(props) => (props.isSideMenuShow ? 0.8 : 0)};
    visibility: ${(props) => (props.isSideMenuShow ? "visible" : "hidden")};
`;

const Message = styled.div`
    max-width: 1200px;
    margin: 1rem 0.5rem;
    padding: 1rem;
    font-size: 1.4rem;
    border-radius: 3px;
    color: ${(props) => (props.color ? props.color : "black")};
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : COLORS.greens[4]};

    @media only screen and (min-width: 1200px) {
        margin: 1rem auto;
    }
`;

const App = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const error = useSelector(selectError);
    if (error) {
        // console.log(error.message);
    }

    const { loginIfToken, accessToken, _id } = useAuth();

    const [isAppLoading, setIsApploading] = useState(true);
    const [showNavbar, setShowNavbar] = useState(true);
    const [isNavbarVisible, setIsNavbarVisible] = useState(false);
    const [showSideMenu, setShowSideMenu] = useState(false);
    const socket = useContext(SocketContext);
    const { toasts, removeToast, addMessage } = useToast();

    useScrollPosition(
        ({ prevPos, currPos }) => {
            const isVisible = location.pathname === "/" || currPos.y < 0;
            const isShow =
                location.pathname === "/" ||
                currPos.y >= -10 ||
                currPos.y > prevPos.y;
            if (isVisible !== isNavbarVisible) setIsNavbarVisible(isVisible);
            if (isShow !== showNavbar) setShowNavbar(isShow);
        },
        [showNavbar, isNavbarVisible]
    );

    // check token => auto login
    useEffect(() => {
        const checkTokenBeforeAppLoad = async () => {
            try {
                await loginIfToken.current();
            } catch (err) {
                // no refresh token => ok
                // console.log(err);
            }

            setIsApploading(false);
        };
        checkTokenBeforeAppLoad();
    }, [loginIfToken]);

    const { isShow, close, title, actions, body } = useModal();

    useEffect(() => {
        dispatch(resetError());
        close();
    }, [location.pathname, dispatch, close]);

    useEffect(() => {
        setShowSideMenu(false);
    }, [location.key]);

    const { addOneNotification, getUnReadCountRef, addOneUnReadCountRef } =
        useNotification();

    // useEffect(() => {
    // 	socket.connect();
    // }, []);

    useEffect(() => {
        if (accessToken) {
            // socket.disconnect();

            socket.auth = { token: accessToken };

            socket.connect();
            // console.log("Socket Event Name (after connect)", socket);
            // console.log("Socket Event Name (after connect)", socket);
            // console.log("Socket Event Name (before remove)", socket);

            socket.removeAllListeners("newNotification");

            socket.removeAllListeners("connect_error");

            // console.log("Socket Event Name (before event)", socket);

            socket.on("newNotification", (savedNotification) => {
                // console.log("New Notifications");
                // console.log("savedNotifications", savedNotification);
                addMessage.current({
                    mode: "info",
                    message: savedNotification.message,
                    // sender: savedNotification.sender.username,
                    ...(savedNotification.sender.role !== "ADMIN" && {
                        sender: savedNotification.sender.username,
                    }),
                    goToUrl: savedNotification.goToUrl,
                });
                addOneUnReadCountRef.current();
                addOneNotification.current(savedNotification);
            });

            socket.on("connect_error", async (err) => {
                try {
                    // console.log(err);
                    if (err.message === "Unauthorized") {
                        await loginIfToken.current();
                        socket.auth = { token: accessToken };
                        setTimeout(() => {
                            socket.connect();
                        }, 5000);
                    }
                } catch (err) {
                    console.error(err);
                }
            });

            return () => {
                socket.disconnect();
            };
        }
    }, [
        loginIfToken,
        accessToken,
        dispatch,
        socket,
        addMessage,
        addOneNotification,
        addOneUnReadCountRef,
    ]);

    useEffect(() => {
        if (_id) {
            // console.log("auth._id", auth._id);
            // socket.emit("userOnline", auth._id);
            getUnReadCountRef.current();
        }
    }, [_id, getUnReadCountRef]);

    if (isAppLoading) {
        return <div></div>;
    }

    return (
        <>
            <ToastPortal toasts={toasts} removeToast={removeToast} />

            {/* React Router Scroll Restoration */}
            <ScrollToTop />

            <CustomModal
                showModal={isShow}
                title={title}
                actions={actions}
                handleClose={close}
            >
                {body}
            </CustomModal>

            <SideMenu
                isSideMenuShow={showSideMenu}
                onCloseButtonClick={() => setShowSideMenu(false)}
            />

            <Overlay isSideMenuShow={showSideMenu}></Overlay>

            <div
                style={{
                    display: "flex",
                    minHeight: "100vh",
                    flexDirection: "column",
                }}
            >
                <Navbar
                    show={showNavbar}
                    visible={isNavbarVisible}
                    isSidebarOpen={showSideMenu}
                    onSideBarIconClick={() => setShowSideMenu(true)}
                />

                <div
                    style={{
                        paddingBottom: "8rem",
                    }}
                >
                    {/* Flash Message */}
                    {location.state?.message && (
                        <Message>{location.state?.message}</Message>
                    )}

                    {/* Error Message */}
                    {error && (
                        <Message backgroundColor={COLORS.dangers[5]}>
                            {error.message}
                        </Message>
                    )}

                    {/* Routes */}
                    <Switch>
                        <PublicRoute exact path={`/${ROUTE_PATH.HOME}`}>
                            <Home />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.ORGANIZER_INFO}`}
                        >
                            <OrganizerInfo />
                        </PublicRoute>
                        <PublicRoute exact path={`/${ROUTE_PATH.STORE_INFO}`}>
                            <StoreInfo />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.PROVIDE_ORGANIZER_INFO}`}
                        >
                            <ProvideOrganizerInfo />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.PROVIDE_STORE_INFO}`}
                        >
                            <ProvideStoreInfo />
                        </PublicRoute>
                        <PublicRoute exact path={`/${ROUTE_PATH.ABOUT_US}`}>
                            <AboutUs />
                        </PublicRoute>
                        <PublicRoute exact path={`/${ROUTE_PATH.CONTACT_US}`}>
                            <ContactUs />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.TERMS_AND_CONDITIONS}`}
                        >
                            <TermsAndConditions />
                        </PublicRoute>
                        <PublicRoute exact path={`/${ROUTE_PATH.TERMS_OF_USE}`}>
                            <TermsOfUse />
                        </PublicRoute>
                        <PublicRoute exact path={`/${ROUTE_PATH.PRIVACY}`}>
                            <Privacy />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.BENEFITS_MEMBER}`}
                        >
                            <BenefitsMember />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.BENEFITS_ORGANIZER}`}
                        >
                            <BenefitsOrganizer />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.OPERATION_FLOW}`}
                        >
                            <FlowOperation />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.RESERVATION_FLOW}`}
                        >
                            <FlowReservation />
                        </PublicRoute>
                        <PublicRoute exact path={`/${ROUTE_PATH.CANCEL_FLOW}`}>
                            <FlowCancel />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.IMPORTANT_DATES}`}
                        >
                            <ImportantDates />
                        </PublicRoute>

                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.EVENT_INFO}/:eventId`}
                        >
                            <EventInfo />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.FISHING_APP_HOME}`}
                        >
                            <FishingAppHome />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.SEARCH_EVENT_INFO}`}
                        >
                            <SearchEventInfo />
                        </PublicRoute>
                        <PublicRoute exact path={`/${ROUTE_PATH.EVENT_INFO}`}>
                            <EventInfo />
                        </PublicRoute>

                        {/* Dev */}
                        <PublicRoute exact path="/checkout/:eventId">
                            {/* <StripeWrapper accountId={"acct_1J9ConQPZevN7cVy"}> */}
                            {/* <PaymentForm /> */}
                            <Checkout />
                            {/* </StripeWrapper> */}
                        </PublicRoute>
                        <PublicRoute exact path="/create-account-link">
                            <StripeAccountLink />
                        </PublicRoute>
                        <PublicRoute exact path="/payment-success">
                            <SuccessPayment />
                        </PublicRoute>
                        <PublicRoute exact path="/search-user">
                            <SearchUser />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path="/admin/check-organizer-invoices"
                        >
                            <CheckOrganizerInvoice />
                        </PublicRoute>

                        {/* Auth */}
                        <PublicRoute
                            restricted
                            exact
                            path={`/${ROUTE_PATH.LOGIN}`}
                        >
                            <Login />
                        </PublicRoute>
                        <PublicRoute
                            restricted
                            exact
                            path={`/${ROUTE_PATH.SIGNUP}`}
                        >
                            <SignUp />
                        </PublicRoute>
                        <PublicRoute
                            restricted
                            exact
                            path={`/${ROUTE_PATH.PASSWORD_RESET}`}
                        >
                            <PasswordResetForm />
                        </PublicRoute>
                        <PublicRoute
                            restricted
                            exact
                            path={`/${ROUTE_PATH.PASSWORD_RESET_REENTER_PASSWORD}/:passwordResetToken`}
                        >
                            <PasswordResetReenterPassword />
                        </PublicRoute>
                        <PublicRoute exact path={`/${ROUTE_PATH.CHECK_EMAIL}`}>
                            {/* Email Confirmation */}
                            <CheckEmailAcknowledgement />
                        </PublicRoute>
                        <PublicRoute
                            exact
                            path={`/${ROUTE_PATH.EMAIL_CONFIRMATION}/:emailToken`}
                        >
                            <EmailConfirmation />
                        </PublicRoute>
                        <PrivateRoute
                            exact
                            path={`/${ROUTE_PATH.CREATE_STRIPE_ACCOUNT}`}
                        >
                            {/* Create Stripe Account */}
                            <CreateStripeAccount />
                        </PrivateRoute>

                        <Route exact path={`/${ROUTE_PATH.PROFILE}/:id?`}>
                            {/* Main routes */}
                            {/* Profile can be viewed by everyone but more info for him/herself */}
                            <Profile />
                        </Route>

                        <Route exact path={`/${ROUTE_PATH.SHIP_INFO}/:id`}>
                            <ShipInfo />
                        </Route>

                        <PrivateRoute
                            roles={["ORGANIZER"]}
                            exact
                            path={`/${ROUTE_PATH.SHIPS_LIST}`}
                        >
                            <ShipsList />
                        </PrivateRoute>

                        <Route
                            exact
                            path={`/${ROUTE_PATH.SHIP_INFO}/:shipId/${ROUTE_PATH.FISHING_LOCATION_PROFILE_INFO}/:id`}
                        >
                            <FishingLocationProfileInfo />
                        </Route>

                        <PrivateRoute
                            roles={["ORGANIZER"]}
                            exact
                            path={`/${ROUTE_PATH.SHIP_MANAGEMENT_FORM}/:shipId/${ROUTE_PATH.FISHING_LOCATION}/:id?`}
                        >
                            <OrganizerLocationManagement />
                        </PrivateRoute>
                        <PrivateRoute
                            roles={["ORGANIZER"]}
                            exact
                            path={`/${ROUTE_PATH.SHIP_MANAGEMENT_FORM}/:id?`}
                        >
                            <ShipManagementForm />
                        </PrivateRoute>
                        <PrivateRoute
                            roles={["ORGANIZER"]}
                            exact
                            path={`/${ROUTE_PATH.EVENTS_LIST}`}
                        >
                            <EventsList />
                        </PrivateRoute>

                        <PrivateRoute
                            roles={["ORGANIZER"]}
                            exact
                            path={`/${ROUTE_PATH.EVENT_MANAGEMENT}/:eventId`}
                        >
                            <EventManagement />
                        </PrivateRoute>
                        <PrivateRoute
                            roles={["ORGANIZER"]}
                            exact
                            path={`/${ROUTE_PATH.CREATE_EVENT}`}
                        >
                            <CreateEventInfoForm />
                        </PrivateRoute>
                        <PrivateRoute
                            roles={["ORGANIZER"]}
                            exact
                            path={`/${ROUTE_PATH.ORGANIZER_INVOICE}`}
                        >
                            <OrganizerInvoice />
                        </PrivateRoute>
                        <PrivateRoute
                            roles={["PARTICIPANT"]}
                            exact
                            path={`/participant-cancel-reservation-form/:eventId`}
                        >
                            <ParticipantCancelReservationForm />
                        </PrivateRoute>
                        <PrivateRoute
                            roles={["PARTICIPANT"]}
                            exact
                            path={`/${ROUTE_PATH.PARTICIPANT_RESERVATION_FORM}/:eventId`}
                        >
                            <ParticipantReservationForm />
                        </PrivateRoute>
                        <PrivateRoute
                            roles={["PARTICIPANT"]}
                            exact
                            path={`/${ROUTE_PATH.RESERVATIONS_LIST}`}
                        >
                            <ReservationsList />
                        </PrivateRoute>
                        {/* <PrivateRoute
                            roles={["PARTICIPANT"]}
                            exact
                            path={`/${ROUTE_PATH.PARTICIPANT_RESERVATION}`}
                        >
                            <ParticipantReservation />
                        </PrivateRoute> */}

                        <PrivateRoute
                            roles={["PARTICIPANT", "ORGANIZER"]}
                            exact
                            path={`/${ROUTE_PATH.EDIT_PROFILE}`}
                        >
                            <EditProfile />
                        </PrivateRoute>
                        <PrivateRoute
                            roles={["PARTICIPANT", "ORGANIZER"]}
                            exact
                            path={`/${ROUTE_PATH.NOTIFICATION}`}
                        >
                            <Notification />
                        </PrivateRoute>

                        {/* Fallback route */}
                        <Route>
                            <NotFound />
                        </Route>
                    </Switch>
                </div>

                <Footer />
            </div>
        </>
    );
};

const WrappedApp = () => {
    return (
        <SocketProvider>
            <QueryClientProvider client={queryClient}>
                <ToastProvider>
                    <ModalProvider>
                        <ReduxProvider store={store}>
                            <ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                onError={(error, errorInfo) => {
                                    // console.log(
                                    //     "Error Boundary Logging",
                                    //     error,
                                    //     errorInfo
                                    // )
                                }}
                            >
                                <Suspense fallback={<PageLoading />}>
                                    <ConnectedRouter history={history}>
                                        <App />
                                    </ConnectedRouter>
                                </Suspense>
                            </ErrorBoundary>
                        </ReduxProvider>
                    </ModalProvider>
                </ToastProvider>
            </QueryClientProvider>
        </SocketProvider>
    );
};

export default WrappedApp;
