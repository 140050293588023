import {
    faFacebook,
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import {
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BREAKPOINTS } from "../../constants/breakpoints";
import { COLORS } from "../../constants/colors";
import { ROUTE_PATH } from "../../constants/routePaths";
import Container from "../Container";
import H2 from "../typography/H2";

const FooterContainer = styled.div`
    padding: 8rem 0 1rem;
    background-color: ${COLORS.greyBlues[5]};
    color: white;
    font-size: 1.4rem;
    margin-top: auto;
`;

const FooterLinksContainer = styled(Container)`
    font-size: 1.4rem;
    color: white;
    justify-content: space-evenly;
    display: grid;
    grid-template-rows: repeat(4, auto);
    row-gap: 50px;
    @media only screen and (min-width: ${BREAKPOINTS.lg}px) {
        grid-template-rows: none;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 0;
        column-gap: 50px;
        text-align: left;
    }
`;

const FooterLinksColumn = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FooterLink = styled(Link)`
    padding: 1rem 0;
`;
const FooterALink = styled.a`
    padding: 1rem 0;
`;

const FooterLinkIcon = styled(FontAwesomeIcon)`
    padding-right: 0.5rem;
`;

const CopyRightContainer = styled(Container)`
    display: block;
    width: 75%;
    margin: 0 auto;
    padding: 2rem 6rem 1rem;
    margin-top: 4rem;
    border-top: 1px solid white;
    text-align: center;
`;
const CopyRightText = styled.span`
    font-family: "Roboto", "Noto Sans HK", sans-serif;
    font-weight: 100;
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterLinksContainer>
                <FooterLinksColumn>
                    <H2 style={{ marginBottom: "1rem", fontWeight: "bold" }}>聯絡我們</H2>
                    <FooterALink href="mailto:waterfunsquare@gmail.com" target="_blank"><FooterLinkIcon icon={faEnvelope} />waterfunsquare@gmail.com</FooterALink>
                    <FooterALink href="https://www.facebook.com/groups/waterfunsquare" target="_blank" rel="noreferrer"><FooterLinkIcon icon={faFacebook} />WaterFun Square 水樂坊</FooterALink>
                    <FooterALink href="https://api.whatsapp.com/send/?phone=85291892762&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer"><FooterLinkIcon icon={faWhatsapp} />釣魚人 +85291892762</FooterALink>
                </FooterLinksColumn>
                <FooterLinksColumn>
                    <H2 style={{ marginBottom: "1rem", fontWeight: "bold" }}>關於我們</H2>
                    <FooterLink to="/about-us">關於水樂坊</FooterLink>
                    <FooterLink to={`/${ROUTE_PATH.CONTACT_US}`}>聯絡我們</FooterLink>
                </FooterLinksColumn>
                <FooterLinksColumn>
                    <H2 style={{ marginBottom: "1rem", fontWeight: "bold" }}>了解更多</H2>
                    <FooterLink to={`/${ROUTE_PATH.OPERATION_FLOW}`}>水樂坊運作流程</FooterLink>
                    <FooterLink to={`/${ROUTE_PATH.RESERVATION_FLOW}`}>預約活動流程</FooterLink>
                    <FooterLink to={`/${ROUTE_PATH.CANCEL_FLOW}`}>取消活動流程</FooterLink>
                    <FooterLink to={`/${ROUTE_PATH.IMPORTANT_DATES}`}>重要日子及時間</FooterLink>
                    <FooterLink to={`/${ROUTE_PATH.BENEFITS_MEMBER}`}>對會員的好處</FooterLink>
                    <FooterLink to={`/${ROUTE_PATH.BENEFITS_ORGANIZER}`}>對主辦單位的好處</FooterLink>
                </FooterLinksColumn>
                <FooterLinksColumn>
                    <H2 style={{ marginBottom: "1rem", fontWeight: "bold" }}>條款</H2>
                    <FooterLink to={`/${ROUTE_PATH.TERMS_AND_CONDITIONS}`}>條款及細則</FooterLink>
                    <FooterLink to={`/${ROUTE_PATH.TERMS_OF_USE}`}>使用條款</FooterLink>
                    <FooterLink to={`/${ROUTE_PATH.PRIVACY}`}>私隱政策</FooterLink>
                </FooterLinksColumn>
            </FooterLinksContainer>
            <CopyRightContainer>
                <CopyRightText>Copyright © 2023 WaterFun Square 水樂坊. All rights reserved.</CopyRightText>
            </CopyRightContainer>
        </FooterContainer>
    );
};

export default Footer;
