import React from "react";
import styled from "styled-components";
import { BsFillPersonFill } from "react-icons/bs";
import { COLORS } from "../constants/colors";

const Container = styled.div`
    background-color: ${COLORS.riceYellows[1]};
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1rem 0.4rem;
`;

const ImageContainer = styled.div`
    background-image: ${(props) => `url(${props.backgroundImage})`};
    background-size: cover;
    background-position: center center;
    height: 10rem;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100%;
    height: 100%;
    font-size: 1.4rem;
`;

const ContentRowContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const ShipProfileBox = ({ canBeChartered, seatAmount, shipName, imageURIs }) => {
    const imageUrl = "https://via.placeholder.com/80";

    return (
        <Container>
            <ContentContainer>
                <ImageContainer
                    backgroundImage={
                        (imageURIs && `${imageURIs[0]}?x-oss-process=image/resize,h_300`) ||
                        imageUrl
                    }
                />
                <span style={{ justifySelf: "center" }}>{shipName}</span>
                <ContentRowContainer>
                    <BsFillPersonFill size={18} />
                    <span>{seatAmount}位</span>
                </ContentRowContainer>
                {/* <ContentRowContainer>
                    {canBeChartered ? <BsCheck size={18} /> : <BsX size={18} />}
                    <span>包船</span>
                </ContentRowContainer> */}
            </ContentContainer>
        </Container>
    );
};

export default ShipProfileBox;
