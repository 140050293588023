import styled from "styled-components";

const Container = styled.div`
    max-width: 1200px;
    width: 100%;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
`;

export default Container;
