import { useMemo } from "react";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

export const Toast = ({ mode, onClose, message, sender, goToUrl }) => {
    const classes = useMemo(
        () => [styles.toast, styles[mode]].join(" "),
        [mode]
    );

    return (
        // <Link to={{ pathname: goToUrl }} onClick={onClose}>
        goToUrl ? (
            <Link to={goToUrl} onClick={onClose}>
                <div className={classes}>
                    <span className={styles.message}>
                        {sender && `${sender}:`} {message}
                    </span>
                </div>
            </Link>
        ) : (
            <div className={classes} onClick={onClose}>
                <span className={styles.message}>
                    {sender && `${sender}:`} {message}
                </span>
            </div>
        )
    );
};
