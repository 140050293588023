import ReactDOM from "react-dom";
import { Toast } from "../Toast";

import styles from "./styles.module.css";
import { useToastPortal } from "../../../hooks/useToastPortal";

export const ToastPortal = ({ toasts, removeToast }) => {
	const { loaded, portalId } = useToastPortal();

	return loaded ? (
		ReactDOM.createPortal(
			<div className={styles.toastContainer}>
				{toasts.map((t) => (
					<Toast
						key={t.id}
						mode={t.mode}
						message={t.message}
						sender={t.sender}
						onClose={() => {
							removeToast(t.id);
						}}
						goToUrl={t.goToUrl}
					/>
				))}
			</div>,

			document.getElementById(portalId)
		)
	) : (
		<></>
	);
};
